import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import DSOSalesOutstandingTrend from '../../v2/DSOSalesOutstandingTrend';
import get from 'lodash.get';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';

const DSOTrend = ({ isAllExpanded, data }) => {
  const dsoTrend = get(data, 'dsoHistory.dsoSummary', []);
  const hasSectionData = checkHasSectionData(dsoTrend);

  return (
    <ReviewContainer
      subHeading="Days Sales Outstanding (DSO) Trend"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <DSOSalesOutstandingTrend data={dsoTrend} noContainer />
      ) : (
        <p>There is no financial data available to display.</p>
      )}
    </ReviewContainer>
  );
};

export default DSOTrend;
