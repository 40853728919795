import api from 'api';
import GoogleMap from 'google-map-react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import React, { useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';

import useParamsCompare from '../../hooks/useParamsCompare';
import useRequestQuery from '../../hooks/useRequestQuery';
import { Props } from '../Panel/model';
import styles from './ChannelMapChart.css';
import { ErrorState } from './ErrorState';
import styled from 'styled-components';
import { COLORS } from 'variables/theme';
import { Typography } from '@material-ui/core';

const channels = ['All', 'Website', 'Rep', 'Location'];

const AU_CENTRE = [-28.5, 134.5];
const NZ_CENTRE = [-41.3, 174.5];
const AU_DEFAULT_ZOOM = 4.2;
const NZ_DEFAULT_ZOOM = 5.3;

const CHANNEL_MARKER_COLOR = {
  Location: '#ff6337',
  Rep: '#ffb800',
  Website: '#00afef',
};

const ChannelSelectorContainer = styled.div<{
  $color: string;
  $isActive: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 12px;
  border-radius: 100px;
  background-color: ${(props) => (props.$isActive ? COLORS.lightGrey : '#fff')};
  box-shadow: ${(props) => props.$isActive && `0px 4px 8px rgba(0,0,0,0.2)`};
  cursor: pointer;
  transition: all 200ms;
  &:hover {
    background-color: ${COLORS.lightGrey};
  }
  div {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background-color: ${(props) => props.$color};
  }
`;

const ChannelSelector = ({ channel, isActive, onClick }) => {
  const color = CHANNEL_MARKER_COLOR[channel];
  return (
    <ChannelSelectorContainer
      $color={color}
      $isActive={isActive}
      onClick={onClick}
    >
      {channel !== 'All' && <div />}
      <Typography>{channel}</Typography>
    </ChannelSelectorContainer>
  );
};

const Marker: React.FC<{
  lng?: number;
  lat?: number;
  color: string;
  isLegend?: boolean;
}> = ({ color, isLegend = false }) => (
  <div
    className={isLegend ? styles.markerLegend : styles.marker}
    style={{ backgroundColor: color }}
  />
);

const Map = ({ data, region }) => {
  const mapRef = useRef(null);

  const onMapLoad = ({ map, maps }) => {
    mapRef.current = map;

    const markers = data.map(
      ({ channel, latitude, longitude }) =>
        new maps.Marker({
          position: { lat: latitude, lng: longitude },
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: CHANNEL_MARKER_COLOR[channel],
            fillOpacity: 1,
            strokeWeight: 0,
          },
          title: channel,
        })
    );

    new MarkerClusterer({ markers, map });
  };

  return (
    <GoogleMap
      defaultCenter={region === 'AU' ? AU_CENTRE : NZ_CENTRE}
      defaultZoom={region === 'AU' ? AU_DEFAULT_ZOOM : NZ_DEFAULT_ZOOM}
      bootstrapURLKeys={{
        key: window._env_.GOOGLE_CLIENT,
      }}
      style={{
        height: '100%',
        margin: 0,
        minHeight: '45rem',
        padding: 0,
        position: 'relative',
        width: '100%',
      }}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={onMapLoad}
      options={() => ({
        styles: [
          {
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
        ],
      })}
    />
  );
};

export const ChannelMapChart: React.FC<Props> = connect((state) => ({
  region: state.current_user.current_entity?.attributes?.region,
}))(({ accessToken, entityId, filterState, region }) => {
  const [channel, setChannel] = React.useState('All');

  const apiAction = () =>
    api('reporting', accessToken, entityId).get({
      api: 'omni_channel_distribution',
      params: filterState,
    });
  const { data, error, forceUpdate, loading } = useRequestQuery(apiAction);

  const paramString = JSON.stringify(filterState);
  const { oldParams, setOldParams } = useParamsCompare(paramString);

  useEffect(() => {
    if (paramString !== oldParams) {
      setOldParams(paramString);
      forceUpdate();
    }
  }, [filterState]);

  if (loading && !error) {
    return <Skeleton count={5} />;
  }

  if (!loading && error) {
    return <ErrorState refresh={forceUpdate} />;
  }

  const selectChannel = (channel: string) => setChannel(channel);

  return (
    <React.Fragment>
      <div className={styles.legendWrapper}>
        {channels.map((channelItem) => (
          <ChannelSelector
            key={channelItem}
            channel={channelItem}
            isActive={channel === channelItem}
            onClick={() => selectChannel(channelItem)}
          />
        ))}
      </div>
      {data && (
        <Map
          key={channel}
          data={
            channel === 'All'
              ? data
              : data.filter((item) => item.channel === channel)
          }
          region={region}
        />
      )}
    </React.Fragment>
  );
});
