import AgingSummaryGraph from './AgingSummaryGraph';
import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';

var DebtorExposureAgingSummary = createClass({
  render() {
    const { data, isAllExpanded } = this.props;

    return (
      <ReviewContainer
        subHeading="Debtor Exposure and Aging Summary"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>
          <strong>Debtor Exposure and Aging Summary</strong>
        </p>
        <p>
          This Risk Indicator does not take into account Directors' personal
          credit information. We therefore strongly recommend you run a
          CompanyExtra report which will provide you with this additional
          information for assessment purposes.
        </p>
        <AgingSummaryGraph data={data} />
      </ReviewContainer>
    );
  },
});

export default DebtorExposureAgingSummary;
