import React from 'react';
import moment from 'moment';

import styles from '../../../css/CommonReviewBusiness.css';
import historyStyles from '../css/History.css';

var StatusHistory = createClass({
  _statuses() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      statusesCollection =
        enquiryResult.company_details.company_previous_statuses || {},
      statuses = statusesCollection.company_previous_status_ws_dto || [];

    if (statuses.constructor === Object) {
      statuses = [statuses];
    }

    return statuses.map((status, index) => {
      return (
        <div key={index}>
          <h4>Previous Status</h4>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Status</th>
                <td>{status.previous_status_description}</td>
              </tr>
              <tr>
                <th>Changed on</th>
                <td>{moment(status.date_changed).format('DD-MMM-YYYY')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  },

  render() {
    return (
      <div className={historyStyles.container}>
        <p>
          <strong>Status History</strong>
        </p>
        {this._statuses()}
      </div>
    );
  },
});

export default StatusHistory;
