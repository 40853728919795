import React, { Fragment } from 'react';
import logo from 'images/credit-works-logo-v2.png';

import {
  HeaderContainer,
  HeaderLogo,
  HeaderLogoContainer,
  H4Title,
  TwoColumnWrapper,
  SummaryWrapper,
  H3Wrapped,
  ScoreCardWrapper,
} from '../shared/styles';
import { getHeaderData } from '../shared/utils';

const Header = ({ data }) => {
  const headerData = getHeaderData(data);

  const {
    averageScore,
    medianScore,
    giniCoefficient,
    companyName,
    scoreCard,
    chanceOfDefault,
  } = headerData || {};

  return (
    <Fragment>
      <HeaderContainer>
        <H4Title>Credit Report</H4Title>
        <HeaderLogoContainer>
          <HeaderLogo src={logo} alt="Creditworks" />
        </HeaderLogoContainer>
      </HeaderContainer>
      <SummaryWrapper>
        <H3Wrapped>{companyName}</H3Wrapped>
        {scoreCard && scoreCard > 0 ? (
          <TwoColumnWrapper>
            <div>
              {companyName} fits the profile of a company with a{' '}
              {chanceOfDefault.toFixed(2)}% chance of default, receivership, or
              liquidation in the next 18 months.
            </div>
            <div>
              <div>
                Scores range from zero to 1000. the score is calculated from
                information valid as at the end of last month. This score was
                calculated with a GINI coefficient of {giniCoefficient}%. The
                average score for all companies is {averageScore}; the median
                score for all companies is {medianScore}.
              </div>
              <ScoreCardWrapper>{scoreCard}</ScoreCardWrapper>
            </div>
          </TwoColumnWrapper>
        ) : (
          <div>{companyName} Credit score is not available</div>
        )}
      </SummaryWrapper>
    </Fragment>
  );
};

export default Header;
