import TextField from '@material-ui/core/TextField';
import styles from 'modules/shared/components/inputs/css/BorderedTextArea.css';
import React from 'react';

function rootClasses(props) {
  const { withBottomMargin } = props;
  const defaultClasses = [styles.root];

  if (withBottomMargin) {
    defaultClasses.push(styles.root_with_margin);
  }

  return defaultClasses.join(' ');
}

export default function BorderedTextArea(props) {
  const {
    labelShrink,
    customProps,
    error,
    darkerDisabledText,
    showOptional,
    helperText,
    ...textAreaProps
  } = props;
  const { rows, rowsMax } = textAreaProps;

  const helperClasses = [styles.form_helper_text_root];

  const showOptionalText =
    !props.required && showOptional ? 'This field is optional' : undefined;

  if (error) {
    helperClasses.push(styles.form_helper_text_root_error);
  }

  return (
    <TextField
      multiline
      fullWidth
      variant="outlined"
      rows={rows}
      rowsMax={rowsMax}
      classes={{
        root: rootClasses({ ...defaultCustomProps, ...customProps }),
      }}
      InputProps={{
        classes: {
          root: styles.input_root,
          disabled: styles.disabled,
          input: darkerDisabledText
            ? styles.darker_disabled_input
            : styles.input,
          notchedOutline: styles.notched_outline,
        },
        ...customProps,
      }}
      InputLabelProps={{
        classes: {
          outlined: styles.outlined,
          root: styles.input_label_root,
          shrink: labelShrink,
        },
      }}
      FormHelperTextProps={{
        classes: {
          root: helperClasses.join(' '),
        },
        margin: 'dense',
      }}
      error={!!error}
      helperText={!!error ? helperText : showOptionalText}
      {...textAreaProps}
    />
  );
}

const defaultCustomProps = {
  withBottomMargin: true,
};

BorderedTextArea.defaultProps = {
  customProps: defaultCustomProps,
  rows: 3,
  rowsMax: 3,
};
