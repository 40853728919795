import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
import { getAddresses } from '../shared/utils';

const ADDRESSES_TABLE_CONF = {
  physicalAddress: { label: 'Physical Address' },
  registeredAddress: { label: 'Registered Address' },
};

const AddressesContent = ({ addresses }) => (
  <CreditWorksDataTable tableConfig={ADDRESSES_TABLE_CONF} data={addresses} />
);

const Addresses = ({ isAllExpanded, data }) => {
  const addresses = getAddresses(data);
  const hasSectionData = checkHasSectionData(addresses);

  return (
    <ReviewContainer
      subHeading="Addresses"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <AddressesContent addresses={addresses} />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default Addresses;
