import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
import get from 'lodash.get';

const DIRECTOR_AFFILIATIONS_TABLE_CONF = {
  groupName: { label: 'Company' },
  companyNumber: { label: 'Number' },
  entityStatusDescription: { label: 'Status' },
  creditLimit: { label: 'Credit Limit' },
  vendorCount: { label: 'Suppliers' },
  dueThisMonth: { label: 'DSO (EoM)' },
};

const DirectorAffiliationsContent = ({ directorAffiliation }) => (
  <CreditWorksDataTable
    fullWidth
    colsWidth="2fr repeat(5, 1fr)"
    tableConfig={DIRECTOR_AFFILIATIONS_TABLE_CONF}
    data={directorAffiliation}
    layout="horizontal"
  />
);

const DirectorAffiliation = ({ isAllExpanded, data }) => {
  const directorAffiliation = get(
    data,
    'companyDetails.associationsAffiliations.directors',
    []
  );
  const hasSectionData = checkHasSectionData(directorAffiliation);

  return (
    <ReviewContainer
      subHeading="Director Affiliations"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <DirectorAffiliationsContent
          directorAffiliation={directorAffiliation}
        />
      ) : (
        <p>There are no Director Affiliations on file for this company.</p>
      )}
    </ReviewContainer>
  );
};

export default DirectorAffiliation;
