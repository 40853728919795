import React, { FC } from 'react';

import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import {
  makeStyles,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';

import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import { Input, Listbox, Option, Tags } from './components';
import { useAutocomplete } from './useAutocomplete';
import { OptionType } from './types';
import { FEATURE_FLAGS } from 'conf';

const useStyles = makeStyles({
  root: {
    marginBottom: 8,
    paddingBottom: 15,
  },
  popper: {
    // Such a high z-index is needed because the IUF preview panel has a high z-index
    // and we need to display the autocomplete dropdown list on top of it
    zIndex: 1000000,
  },
  //@ts-ignore &> ul unrecognised by ts as css
  listbox: (props?: any) =>
    props.includeOptions
      ? {
          height: 'auto !important',
          maxHeight: '300px !important',
          '&> ul': {
            height: 'auto !important',
            pointerEvents: 'all !important',
          },
        }
      : {},
  option: (props?: any) =>
    props.includeOptions
      ? {
          position: 'unset !important' as any,
          height: 'auto !important' as any,
          paddingTop: '0',
          paddingBottom: '0',
        }
      : {},
});

type AutocompleteProps = {
  id: string;
  options: OptionType[];
  label: string;
  error: string;
  required: boolean;
  multiple: boolean;
  hasMainOption: boolean;
  labelShrink: boolean;
  onChange: (
    value: string | string[] | { main: string | null; other: string[] }
  ) => void;
  value: { main: string | null; other: string[] };
  borderedStyle?: boolean;
};

const Autocomplete: FC<AutocompleteProps> = (props) => {
  const {
    id,
    options,
    label,
    error,
    required,
    multiple,
    hasMainOption,
    labelShrink,
    onChange,
    value,
    borderedStyle,
  } = props;

  const { mainValue, otherValue, handleSingleClick, handleToggleMain, rows } =
    useAutocomplete({
      multiple,
      hasMainOption,
      options,
      onChange,
      value,
    });

  const classes = useStyles({ includeOptions: borderedStyle });

  const helperTextArray: string[] = [];
  if (!required) helperTextArray.push('This field is optional.');
  if (hasMainOption)
    helperTextArray.push(
      'Please select your main option using the slider on the right hand side of dropdown menu.'
    );
  const helperText = error || helperTextArray.join(' ');

  const selectedOptions: OptionType[] = [];
  if (mainValue) {
    const mainOption = options.find((option) => option.value === mainValue);
    if (mainOption) {
      selectedOptions.push(mainOption);
    }
  }
  if (otherValue.length) {
    options.forEach((option) => {
      if (otherValue.includes(option.value)) {
        selectedOptions.push(option);
      }
    });
  }
  const defaultValue_old =
    selectedOptions.length === 1 && !multiple
      ? selectedOptions[0]
      : selectedOptions;

  const iufFixEnabled = FEATURE_FLAGS.FEATURE_FLAG_IUF_PERFORMANCE_FIX;

  const selectedOptionsLength = selectedOptions.length;
  const hasSingleValue = selectedOptionsLength === 1 && !multiple;
  const hasMultipleSelection = !!selectedOptionsLength;
  const defaultValue_new = hasSingleValue
    ? selectedOptions[0]
    : hasMultipleSelection
    ? selectedOptions
    : undefined;

  const defaultValue = iufFixEnabled ? defaultValue_new : defaultValue_old;

  return iufFixEnabled ? (
    <MuiAutocomplete
      key={multiple ? 'multiple' : 'single'}
      classes={{ ...classes }}
      disableClearable
      disableCloseOnSelect={multiple}
      id={id}
      options={options}
      getOptionLabel={(option) => option.label || ''}
      getOptionSelected={(option) =>
        option.value === mainValue || otherValue.includes(option.value)
      }
      defaultValue={defaultValue as any}
      multiple={multiple}
      renderInput={(params) => (
        <Input
          error={!!error}
          helperText={helperText}
          label={label}
          labelShrink={labelShrink}
          renderInputParams={params}
          required={required}
        />
      )}
      renderTags={() => (
        <Tags
          mainValue={mainValue}
          selectedOptions={selectedOptions}
          onDelete={handleSingleClick}
        />
      )}
      ListboxComponent={
        Listbox as React.ComponentType<React.HTMLAttributes<HTMLElement>>
      }
      renderOption={(option) => (
        <Option
          mainValue={mainValue}
          multiple={multiple}
          hasMainOption={hasMainOption}
          option={option}
          onClick={handleSingleClick}
          onToggleMain={handleToggleMain}
          rows={rows}
          borderedStyle={borderedStyle}
        />
      )}
      size="small"
    />
  ) : (
    <MuiThemeProvider theme={muiTheme()}>
      <MuiAutocomplete
        key={multiple ? 'multiple' : 'single'}
        classes={{ ...classes }}
        disableClearable
        disableCloseOnSelect={multiple}
        id={id}
        options={options}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option) =>
          option.value === mainValue || otherValue.includes(option.value)
        }
        defaultValue={defaultValue as any}
        multiple={multiple}
        renderInput={(params) => (
          <Input
            error={!!error}
            helperText={helperText}
            label={label}
            labelShrink={labelShrink}
            renderInputParams={params}
            required={required}
          />
        )}
        renderTags={() => (
          <Tags
            mainValue={mainValue}
            selectedOptions={selectedOptions}
            onDelete={handleSingleClick}
          />
        )}
        ListboxComponent={
          Listbox as React.ComponentType<React.HTMLAttributes<HTMLElement>>
        }
        renderOption={(option) => (
          <Option
            mainValue={mainValue}
            multiple={multiple}
            hasMainOption={hasMainOption}
            option={option}
            onClick={handleSingleClick}
            onToggleMain={handleToggleMain}
            rows={rows}
          />
        )}
        size="small"
      />
    </MuiThemeProvider>
  );
};

export default Autocomplete;
