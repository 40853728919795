import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import {
  HeaderContainer,
  HeaderLogo,
  HeaderLogoContainer,
} from '../shared/styles';
import logo from 'images/credit-works-logo-v2.png';

const ErrorPage = () => {
  return (
    <HeaderContainer>
      <ReviewContainer
        subHeading={'Credit Report'}
        content_class="content_wide"
        css_class="block_noborder"
      >
        <p>
          Oops! Something went wrong, please contact{' '}
          <a href="mailto:support@1centre.com">support@1centre.com</a> for more
          details.
        </p>
      </ReviewContainer>
      <HeaderLogoContainer>
        <HeaderLogo src={logo} alt="Creditworks" />
      </HeaderLogoContainer>
    </HeaderContainer>
  );
};

export default ErrorPage;
