import { FEATURE_FLAGS } from 'conf';
import CreditWorksComponentV1 from 'modules/applications/components/credit-checks/credit-works/v1/CreditWorksComponent';
import CreditWorksComponentV2 from 'modules/applications/components/credit-checks/credit-works/v2/CreditWorksComponent';
import CreditWorksComponentV3 from 'modules/applications/components/credit-checks/credit-works/v3/CreditWorksComponent';
import React, { ReactElement } from 'react';

interface Props {
  version: number;
}

const Components = {
  1: CreditWorksComponentV1,
  2: FEATURE_FLAGS.FEATURE_FLAG_CREDITWORKS_UI_REFRESH
    ? CreditWorksComponentV3 //Not using version 3 in BE as data for v2 and v3 is the same
    : CreditWorksComponentV2,
};

function CreditWorksComponent(props: Props): ReactElement {
  const { version } = props;
  const Component = Components[version];

  return <Component {...props} />;
}

export default CreditWorksComponent;
