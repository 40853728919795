import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
import { getShareholders } from '../shared/utils';

const SHAREHOLDERS_TABLE_CONF = {
  shareholdersName: { label: 'Name' },
  shareholdersAddress: { label: 'Address' },
  numberOfShares: { label: 'Number of shares' },
};

const ShareholdersContent = ({ shareholders }) =>
  shareholders.map((shareholder) => (
    <CreditWorksDataTable
      colsWidth="150px 1fr"
      tableConfig={SHAREHOLDERS_TABLE_CONF}
      data={shareholder}
    />
  ));

const Shareholders = ({ isAllExpanded, data }) => {
  const shareholders = getShareholders(data);
  const hasSectionData = checkHasSectionData(shareholders);

  return (
    <ReviewContainer
      subHeading="Shareholders"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <ShareholdersContent shareholders={shareholders} />
      ) : (
        <p>There have been no Shareholders filed for this company</p>
      )}
    </ReviewContainer>
  );
};

export default Shareholders;
