import React from 'react';

import styles from '../../../css/CommonReviewBusiness.css';
import historyStyles from '../css/History.css';

var Addresses = createClass({
  _addresses() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      companyAddressesCollection =
        enquiryResult.company_details.company_addresses || {},
      companyAddresses =
        companyAddressesCollection.company_address_ws_dto || [];

    if (companyAddresses.constructor === Object) {
      companyAddresses = [companyAddresses];
    }

    return companyAddresses.map((address, index) => {
      return (
        <tr key={index}>
          <th>{address.address_type_description}</th>
          <td>{address.address}</td>
        </tr>
      );
    });
  },

  render() {
    return (
      <div className={historyStyles.container}>
        <p>
          <strong>Addresses</strong>
        </p>
        <table className={styles.table_w20}>
          <tbody>{this._addresses()}</tbody>
        </table>
      </div>
    );
  },
});

export default Addresses;
