/* eslint-disable multiline-ternary */
import TextField from '@material-ui/core/TextField';
import styles from 'modules/shared/components/inputs/css/BorderedTextField.css';
import integerise from 'modules/shared/helpers/integerise';
import React from 'react';
import isPresent from 'utils/isPresent';

function rootClasses(props) {
  const { withBottomMargin, muiltiSelectDropdown } = props;
  const defaultStyles = [];

  if (withBottomMargin) {
    defaultStyles.push(styles.root);
  }

  if (muiltiSelectDropdown) {
    defaultStyles.push(styles.multiselect_input);
  }

  return defaultStyles.join(' ');
}

function hasHighlight({ mentions }) {
  return mentions && mentions.every((mention) => !!mention);
}

function inputClasses(props) {
  const { isCompact, outlineError } = props;
  const isHighlighted = hasHighlight(props);

  if (outlineError) {
    return styles.input_error;
  }

  if (isHighlighted) {
    return styles.input_text_hidden;
  }

  if (isCompact) {
    return styles.input_compact;
  }

  return styles.input;
}

function inputProps(props) {
  const { disabled, endAdornment, startAdornment, outlineError } = props;

  let notchedOutline = styles.notched_outline;

  if (outlineError) {
    notchedOutline = styles.notched_outline_error;
  }

  if (disabled) {
    notchedOutline = styles.notched_outline_disabled;
  }

  const defaultProps = {
    classes: {
      disabled: styles.input_disabled,
      input: inputClasses(props),
      notchedOutline,
    },
  };

  if (isPresent(endAdornment)) {
    defaultProps.endAdornment = endAdornment;
  }

  if (isPresent(startAdornment)) {
    defaultProps.startAdornment = startAdornment;
  }

  return defaultProps;
}

const BorderedTextField = (props) => {
  const {
    customProps,
    error,
    disabled,
    labelShrink,
    mentions,
    number_only,
    loading,
    value,
    rootRef,
    register,
    showOptional,
    helperText,
    ...textFieldProps
  } = props;

  function _applyHighlights(text) {
    // TODO: refactor to remove ESLint disable
    // eslint-disable-next-line no-param-reassign
    text = text.replace(/\n$/g, '\n\n');
    mentions.forEach((mention) => {
      // TODO: refactor to remove ESLint disable
      // eslint-disable-next-line no-param-reassign
      text = text.replace(
        new RegExp(mention, 'g'),
        `<mark
          style="
            color: var(--main-font-color);
            background: var(--main-color);
            padding: 0.25rem;
            margin-left: -0.5rem
          ;">
          ${mention}
        </mark>`
      );
    });

    return text;
  }

  function _formatCommaInteger(text) {
    if (number_only) {
      const integer = integerise(text);
      return integer.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    return text;
  }

  const helperClasses = [styles.form_helper_text_root];

  const formattedValue = _formatCommaInteger(value);

  if (error) {
    helperClasses.push(styles.form_helper_text_root_error);
  }

  const isHighlighted = hasHighlight(props);

  const showOptionalText =
    !props.required && showOptional ? 'This field is optional' : undefined;

  return (
    <div className={styles.wrapper}>
      <TextField
        ref={rootRef}
        fullWidth
        variant="outlined"
        size="small"
        classes={{
          root: rootClasses({ ...defaultCustomProps, ...customProps }),
        }}
        inputProps={{ ...register }}
        InputProps={inputProps({
          ...defaultCustomProps,
          ...customProps,
          disabled,
          mentions,
        })}
        InputLabelProps={{
          classes: {
            disabled: styles.input_label_disabled,
            root: styles.input_label_root,
          },
          shrink: labelShrink,
        }}
        FormHelperTextProps={{
          classes: {
            root: helperClasses.join(' '),
          },
          margin: 'dense',
        }}
        onKeyDown={(e) => {
          if (
            number_only &&
            (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+')
          ) {
            e.preventDefault();
          }
        }}
        error={!!error}
        disabled={disabled}
        value={register ? undefined : formattedValue}
        helperText={!!error ? helperText : showOptionalText}
        {...textFieldProps}
      />
      {isHighlighted && (
        <div
          className={styles.mention}
          dangerouslySetInnerHTML={{
            __html: _applyHighlights(formattedValue),
          }}
        />
      )}
    </div>
  );
};

const defaultCustomProps = {
  isCompact: false,
  withBottomMargin: true,
};

BorderedTextField.defaultProps = {
  customProps: defaultCustomProps,
};

BorderedTextField.displayName = 'BorderedTextField';

export default BorderedTextField;
