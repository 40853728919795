import React, { Fragment } from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import get from 'lodash.get';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';

const PREV_INQUIRIES_TABLE_CONF = {
  key: { keyName: 'enquiryDate', type: 'date' },
  value: { keyName: 'enquirer' },
};

const CreditActivityContent = ({ previousEnquiries, disclaimer }) => (
  <Fragment>
    <em>{disclaimer}</em>
    <CreditWorksDataTable
      tableConfig={PREV_INQUIRIES_TABLE_CONF}
      data={previousEnquiries.reverse()}
      iterateData
    />
  </Fragment>
);

const CreditActivity = ({ isAllExpanded, data }) => {
  const previousEnquiriesData = get(data, 'previousEnquiries', {});
  const { previousEnquiries, disclaimer } = previousEnquiriesData || {};
  const hasSectionData = checkHasSectionData(previousEnquiriesData);

  return (
    <ReviewContainer
      subHeading="Previous Enquiries / Credit Activity"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <CreditActivityContent
          previousEnquiries={previousEnquiries}
          disclaimer={disclaimer}
        />
      ) : (
        <p>There are no Credit Activity on file for this company.</p>
      )}
    </ReviewContainer>
  );
};

export default CreditActivity;
