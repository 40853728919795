import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
import get from 'lodash.get';

const INSOLVENCIES_TABLE_CONF = {
  organisationName: { label: 'Company Name' },
};

const InsolvenciesContent = ({ insolvencies }) =>
  insolvencies.map((insolvency) =>
    insolvency.nzbnInsolvencyAppointee.map((insolvencyData) => (
      <CreditWorksDataTable
        tableConfig={INSOLVENCIES_TABLE_CONF}
        data={insolvencyData}
      />
    ))
  );

const Insolvencies = ({ isAllExpanded, data }) => {
  const insolvencies = get(data, 'companyDetails.nzbnInsolvencyDetails', []);
  const hasSectionData = checkHasSectionData(insolvencies);

  return (
    <ReviewContainer
      subHeading="Insolvencies"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      <em>
        This information is retrieved from the New Zealand Government's Ministry
        of Economic Development, Insolvency and Trustee Service and includes all
        insolvencies administered by the Official Assignee. This excludes
        information on liquidations where the Official Assignee is not the
        liquidator.
      </em>
      <br />
      <br />

      {hasSectionData ? (
        <InsolvenciesContent insolvencies={insolvencies} />
      ) : (
        <p>
          There are no insolvency records filed with the Official Assignee for
          this company.
        </p>
      )}
    </ReviewContainer>
  );
};

export default Insolvencies;
