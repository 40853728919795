import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import { getStatusHistory } from '../shared/utils';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
const STATUS_HISTORY_TABLE_CONF = {
  value: { keyName: 'statusHistory' },
};
const StatusHistoryContent = ({ statusHistory }) => (
  <CreditWorksDataTable
    tableConfig={STATUS_HISTORY_TABLE_CONF}
    data={statusHistory}
    iterateData
    cols={1}
  />
);
const StatusHistory = ({ isAllExpanded, data }) => {
  const statusHistory = getStatusHistory(data);
  const hasSectionData = checkHasSectionData(statusHistory);

  return (
    <ReviewContainer
      subHeading="Status History"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <StatusHistoryContent statusHistory={statusHistory} />
      ) : (
        <p>There are no Status History on file for this company.</p>
      )}
    </ReviewContainer>
  );
};

export default StatusHistory;
