import React from 'react';

import styles from '../../../css/CommonReviewBusiness.css';
import historyStyles from '../css/History.css';

var Judgments = createClass({
  _judgments() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      judgmentsCollection = enquiryResult.judgments_collection || {},
      judgmentDetails = judgmentsCollection.judgment_details_ws_dto || [];

    if (judgmentDetails.constructor === Object)
      judgmentDetails = [judgmentDetails];

    if (judgmentDetails.length === 0) {
      return (
        <div>
          <h4>There have been no judgments filed against this company. </h4>
          <em>Judgments search is limited to the last seven years.</em>
        </div>
      );
    }

    return judgmentDetails.map((judgment, index) => {
      return (
        <div key={index}>
          <h4>Judgment</h4>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Proceeding Type</th>
                <td>{judgment.proceeding_type}</td>
              </tr>
              <tr>
                <th>Nop Number</th>
                <td>{judgment.nop_number}</td>
              </tr>
              <tr>
                <th>Nop Year</th>
                <td>{judgment.nop_year}</td>
              </tr>
              <tr>
                <th>Nop Court</th>
                <td>{judgment.nop_court}</td>
              </tr>
              <tr>
                <th>Nop Variant</th>
                <td>{judgment.nop_variant}</td>
              </tr>
              <tr>
                <th>Civ Number</th>
                <td>{judgment.civ_number}</td>
              </tr>
              <tr>
                <th>Folio Number</th>
                <td>{judgment.folio_number}</td>
              </tr>
              <tr>
                <th>Folio Year</th>
                <td>{judgment.folio_year}</td>
              </tr>
              <tr>
                <th>Sealed Date</th>
                <td>{judgment.sealed_date}</td>
              </tr>
              <tr>
                <th>Type</th>
                <td>{judgment.type}</td>
              </tr>
              <tr>
                <th>Amount</th>
                <td>{judgment.amount}</td>
              </tr>
              <tr>
                <th>Has Multiple Defendants</th>
                <td>{judgment.has_multiple_defendants}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  },

  render() {
    return (
      <div className={historyStyles.container}>
        <p>
          <strong>Judgments</strong>
        </p>
        {this._judgments()}
      </div>
    );
  },
});

export default Judgments;
