import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import { getPastDirectors } from '../shared/utils';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';

const PAST_DIRECTORS_TABLE_CONF = {
  name: { label: 'Name' },
  address: { label: 'Address' },
  startDate: { label: 'Date appointed', type: 'date' },
  endDate: { label: 'Date deleted', type: 'date' },
};

const PastDirectorsContent = ({ pastDirectors }) =>
  pastDirectors.map((director) => (
    <CreditWorksDataTable
      colsWidth="150px 1fr"
      tableConfig={PAST_DIRECTORS_TABLE_CONF}
      data={director}
    />
  ));

const PastDirectors = ({ isAllExpanded, data }) => {
  const pastDirectors = getPastDirectors(data);
  const hasSectionData = checkHasSectionData(pastDirectors);

  return (
    <ReviewContainer
      subHeading="Past Directors"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <PastDirectorsContent pastDirectors={pastDirectors} />
      ) : (
        <p>There have been no Past Directors filed for this company</p>
      )}
    </ReviewContainer>
  );
};

export default PastDirectors;
