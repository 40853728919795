import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import get from 'lodash.get';
import { TwoColumnWrapper } from '../shared/styles';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';

const DEFAULTS_TABLE_CONFIG = {
  reference: { label: 'Reference' },
  accountType: { label: 'Account Type' },
  creditProvider: { label: 'Credit Provider' },
  dataSupplier: { label: 'Data Supplier' },
  dateOfDefault: { label: 'Date Of Default', type: 'date' },
  originalDefaultAmount: { label: 'Original Amount', type: 'money' },
  currentDefaultBalance: { label: 'Current Balance', type: 'money' },
  defaultStatus: { label: 'Status' },
  statusDate: { label: 'Status Date', type: 'date' },
};

const DefaultsContent = ({ defaults }) => (
  <TwoColumnWrapper>
    {defaults.map((defaultData) => (
      <CreditWorksDataTable
        tableConfig={DEFAULTS_TABLE_CONFIG}
        data={defaultData}
      />
    ))}
  </TwoColumnWrapper>
);

const Defaults = ({ isAllExpanded, data }) => {
  const defaults = get(data, 'executiveSummary.defaultList', []);
  const hasSectionData = checkHasSectionData(defaults);

  return (
    <ReviewContainer
      subHeading="Defaults"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <DefaultsContent defaults={defaults} />
      ) : (
        <p>There have been no Defaults filed against this company</p>
      )}
    </ReviewContainer>
  );
};

export default Defaults;
