import React, { Fragment } from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
import { getCompanyRegistration } from '../shared/utils';

const COMPANY_REGISTRATION_TABLE_CONF = {
  companyNumber: { label: 'Company Number' },
  companyName: { label: 'Company Name' },
  status: { label: 'Status' },
  dateIncorparated: { label: 'Date Incorporated', type: 'date' },
  entityTypeDescription: { label: 'Description' },
  hasConstitutionFiled: { label: 'Constitution Filed', type: 'yesno' },
  nzbn: { label: 'NZBN' },
  numberOfShares: { label: 'Number of Shares' },
};

const CompanyRegistrationContent = ({ companyRegistration }) => (
  <CreditWorksDataTable
    tableConfig={COMPANY_REGISTRATION_TABLE_CONF}
    data={companyRegistration}
  />
);

const CompanyRegistration = ({ isAllExpanded, data }) => {
  const companyRegistration = getCompanyRegistration(data);
  const hasSectionData = checkHasSectionData(companyRegistration);

  return (
    <Fragment>
      <ReviewContainer
        subHeading="Company Registration"
        content_class="content_wide"
        collapsible={hasSectionData}
        isAllExpanded={hasSectionData ? isAllExpanded : undefined}
        fontSize="medium"
        css_class="block_medium_bottomborder"
      >
        <em>
          This information was obtained from the New Zealand Companies Office.
        </em>
        {hasSectionData ? (
          <CompanyRegistrationContent
            companyRegistration={companyRegistration}
          />
        ) : (
          <p>
            There are no insolvency records filed with the Official Assignee for
            this company.
          </p>
        )}
      </ReviewContainer>
    </Fragment>
  );
};

export default CompanyRegistration;
