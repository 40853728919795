import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  margin-bottom: 1em;
`;

export const HeaderContents = styled.div``;

export const HeaderLogo = styled.img`
  width: 250px;
`;

export const HeaderLogoContainer = styled.div`
  justify-self: flex-end;
`;

export const H4Wrapped = styled.h4`
  font-size: 18px !important;
`;

export const H4Title = styled(H4Wrapped)`
  margin: 1em 0;
  padding-left: 45px;
`;

export const H3Wrapped = styled.h3`
  font-size: 20px !important;
`;

export const ScoreCardWrapper = styled.h2`
  color: #e95b26 !important;
  font-size: 24px !important;
`;

export const ReportContainer = styled.div`
  padding: 2em;
`;

export const ReportBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em 0;
`;

export const TwoColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4em;
`;

export const SummaryWrapper = styled.div`
  border: 2px solid #e95b26;
  padding: 2em;
  margin: 0 0 2em 2em;
`;

interface ICWTable {
  fullWidth?: any;
  cols?: any;
  colsWidth?: string;
}

export const CWTable = styled.table`
  ${(props: ICWTable) => (!!props.fullWidth ? 'width: 100%;' : '')}
  margin: 2em 0;
  thead,
  tbody {
    tr {
      border: none;
      border-bottom: 1px solid #c9c9c9;
      display: grid;
      grid-template-columns: ${(props: ICWTable) =>
        `repeat(${!!props.cols ? props.cols : 2}, 1fr)`};
      ${(props: ICWTable) =>
        !!props.colsWidth ? `grid-template-columns: ${props.colsWidth};` : ''}

      th {
        font-weight: 500 !important;
      }

      th,
      td {
        padding: 2px;
      }
    }
  }
`;
