import React, { Fragment } from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
import get from 'lodash.get';

const PPSRFinancingSummaryContent = ({ ppsrFinancingSummary }): any => {
  const groupedItems = {};

  ppsrFinancingSummary.forEach(({ collateralTypes }) => {
    collateralTypes.forEach(({ description }) => {
      groupedItems[description] = (groupedItems[description] || 0) + 1;
    });
  });

  const groupedItemsToRender = Object.keys(groupedItems).map(
    (collateral, index) => {
      let total = groupedItems[collateral],
        statement = 'statement';

      if (total > 1) statement = 'statements';

      return (
        <p key={index}>
          {groupedItems[collateral]} finance {statement} with {collateral}
        </p>
      );
    }
  );

  return (
    <Fragment>
      <p>
        Please use the PPSR page to view complete details of PPSR Finance
        Statements:
      </p>
      {groupedItemsToRender}
    </Fragment>
  );
};

const PPSRFinancingSummary = ({ isAllExpanded, data }) => {
  const ppsrFinancingSummary = get(data, 'financingStatements.items', []);
  const hasSectionData = checkHasSectionData(ppsrFinancingSummary);

  return (
    <ReviewContainer
      subHeading="PPSR Financing Statements Collateral Summary"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      <p>
        The following Finance Statement Collateral Items are registered on the
        PPSR against this company.
      </p>
      <p>
        Some finance statements have multiple collateral items so the total
        number of statements might be less than the total of collateral items.
      </p>
      <br />
      {hasSectionData ? (
        <PPSRFinancingSummaryContent
          ppsrFinancingSummary={ppsrFinancingSummary}
        />
      ) : (
        <p>
          There are no Finance Statements registered on the PPSR against this
          company.
        </p>
      )}
    </ReviewContainer>
  );
};

export default PPSRFinancingSummary;
