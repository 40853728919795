import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
import { getPpsrFinancingSummary } from '../shared/utils';

const PPSR_DETAIL_TABLE_CONF = {
  registrationNumber: { label: 'Number' },
  registrationDate: { label: 'Date', type: 'date' },
  securedPartyName: { label: 'Secured Party' },
  collateralDescription: { label: 'Collateral' },
};

const PPSRFinancingDetailsContent = ({ ppsrFinancingDetails }) => (
  <CreditWorksDataTable
    layout="horizontal"
    fullWidth
    cols={4}
    tableConfig={PPSR_DETAIL_TABLE_CONF}
    data={ppsrFinancingDetails}
  />
);

const PPSRFinancingDetails = ({ isAllExpanded, data }) => {
  const ppsrFinancingDetails = getPpsrFinancingSummary(data);
  const hasSectionData = checkHasSectionData(ppsrFinancingDetails);

  return (
    <ReviewContainer
      subHeading="PPSR Financing Statements Detail"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <PPSRFinancingDetailsContent
          ppsrFinancingDetails={ppsrFinancingDetails}
        />
      ) : (
        <p>There are no PPSR financing statements for this company.</p>
      )}
    </ReviewContainer>
  );
};

export default PPSRFinancingDetails;
