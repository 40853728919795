import React, { useState } from 'react';
import styled from 'styled-components';
import { VersionItem } from './VersionItem';
import { formatLocalTime } from 'utils/dateFormatter';
import { Collapse } from '@material-ui/core';
import { FEATURE_FLAGS } from 'conf';

const Container = styled.div`
  position: relative;
  margin-top: 16px;
`;

const Button = styled.img`
  position: relative;
  left: 98%;
  width: 22px;
  height: 22px;
  padding: 5px;
  background: dimgrey;
  border-radius: 100%;
  cursor: pointer;
  transition: all ease-in-out 100ms;

  &:hover {
    background-color: var(--main-color);
  }
`;

type TermsDocumentVersion = {
  attributes: {
    created_at: string;
    file: { url: string };
    version: number;
  };
};

type TermsDocumentHistoryProps = {
  items: TermsDocumentVersion[];
};

export const TermsDocumentHistory = (props: TermsDocumentHistoryProps) => {
  const { items } = props;

  const [isExpanded, setExpanded] = useState(false);

  const toggle = () => setExpanded(!isExpanded);

  if (
    !FEATURE_FLAGS.FEATURE_FLAG_TERMS_DOCUMENT_VERSIONING ||
    !items ||
    !items.length
  ) {
    return null;
  }

  return (
    <Container>
      <Button
        onClick={toggle}
        src={require('images/icon_chevron_down-white.svg')}
      />
      <Collapse in={isExpanded}>
        {items.map((item, index) => (
          <VersionItem
            createdAt={formatLocalTime(item.attributes.created_at, 'minute')}
            versionNumber={item.attributes.version}
            isCurrent={index === 0}
            src={item.attributes.file?.url}
          />
        ))}
      </Collapse>
    </Container>
  );
};
