import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
import get from 'lodash.get';

const PARENT_COMPANIES_TABLE_CONF = {
  groupName: { label: 'Company' },
  companyNumber: { label: 'Co. Number' },
  entityStatusDescription: { label: 'Status' },
  creditLimit: { label: 'Credit Limit' },
  vendorCount: { label: 'No. Suppliers' },
};

const ParentCompaniesContent = ({ parentCompanies }) => (
  <CreditWorksDataTable
    fullWidth
    colsWidth="2fr repeat(4, 1fr)"
    tableConfig={PARENT_COMPANIES_TABLE_CONF}
    data={parentCompanies}
    layout="horizontal"
  />
);

const ParentCompanies = ({ isAllExpanded, data }) => {
  const parentCompanies = get(
    data,
    'companyDetails.associationsAffiliations.parentCompanies',
    []
  );
  const hasSectionData = checkHasSectionData(parentCompanies);

  return (
    <ReviewContainer
      subHeading="Parent Companies"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <ParentCompaniesContent parentCompanies={parentCompanies} />
      ) : (
        <p>There are no Parent Companies on file for this company.</p>
      )}
    </ReviewContainer>
  );
};

export default ParentCompanies;
