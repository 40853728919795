import React from 'react';
import moment from 'moment';

import styles from '../../../css/CommonReviewBusiness.css';
import historyStyles from '../css/History.css';

var Defaults = createClass({
  _defaults() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      defaultsCollection = enquiryResult.defaults_collection || {},
      defaults = defaultsCollection.defaults_ws_dto || [];

    if (defaults.constructor === Object) defaults = [defaults];

    if (defaults.length === 0) {
      return <h4>There have been no defaults lodged against this company.</h4>;
    }

    return defaults.map((def, index) => {
      return (
        <div key={index}>
          <h4>Default</h4>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Reference</th>
                <td>{def.reference}</td>
              </tr>
              <tr>
                <th>Account type</th>
                <td>{def.account_type}</td>
              </tr>
              <tr>
                <th>Credit provider</th>
                <td>{def.credit_provider}</td>
              </tr>
              <tr>
                <th>Agency</th>
                <td>{}</td>
              </tr>
              <tr>
                <th>Data Supplier</th>
                <td>{def.data_supplier}</td>
              </tr>
              <tr>
                <th>Date of Default</th>
                <td>{moment(def.date_of_default).format('DD-MMM-YYYY')}</td>
              </tr>
              <tr>
                <th>Original Amount</th>
                <td>
                  {parseFloat(def.original_default_amount).toLocaleString(
                    'en',
                    { maximumFractionDigits: 2 }
                  )}
                </td>
              </tr>
              <tr>
                <th>Current Balance</th>
                <td>
                  {parseFloat(def.current_default_balance).toLocaleString(
                    'en',
                    { maximumFractionDigits: 2 }
                  )}
                </td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{def.default_status}</td>
              </tr>
              <tr>
                <th>Status Date</th>
                <td>{moment(def.status_date).format('DD-MMM-YYYY')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  },

  render() {
    return (
      <div className={historyStyles.container}>
        <p>
          <strong>Defaults</strong>
        </p>
        {this._defaults()}
      </div>
    );
  },
});

export default Defaults;
