import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
import { getDirectors } from '../shared/utils';

const DIRECTORS_TABLE_CONF = {
  name: { label: 'Name' },
  address: { label: 'Address' },
  startDate: { label: 'Date Appointed', type: 'date' },
};

const DirectorsContent = ({ directors }) =>
  directors.map((director) => (
    <CreditWorksDataTable
      colsWidth="150px 1fr"
      tableConfig={DIRECTORS_TABLE_CONF}
      data={director}
    />
  ));

const Directors = ({ isAllExpanded, data }) => {
  const directors = getDirectors(data);
  const hasSectionData = checkHasSectionData(directors);

  return (
    <ReviewContainer
      subHeading="Directors"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <DirectorsContent directors={directors} />
      ) : (
        <p>There have been no Directors filed for this company</p>
      )}
    </ReviewContainer>
  );
};

export default Directors;
