import 'react-table/react-table.css';

import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import moment from 'moment';
import styles from '../../../css/CommonReviewBusiness.css';

var ExecutiveSummary = createClass({
  _countDisplayValue(collectionKey, dtoKey) {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      collection = enquiryResult[collectionKey] || {};

    let label = 'None';

    if (collection.hasOwnProperty(dtoKey)) {
      let details = collection[dtoKey];
      label = 1;

      if (details.constructor === Array) {
        label = details.length.toString();
      }
    }

    return label;
  },

  _companyNameChanges() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      companyDetails = enquiryResult.company_details;

    let label = 'None',
      historicNames = companyDetails.company_historic_names || {};

    if (historicNames.hasOwnProperty('company_historic_name_ws_dto')) {
      label = 1;
      let names = historicNames.company_historic_name_ws_dto;

      if (names.constructor === Array) {
        label = names.length.toString();
      }
    }

    return label;
  },

  _directors() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      companyDetails = enquiryResult.company_details,
      companyDirectors = companyDetails.company_directors || {},
      directors = companyDirectors.company_director_ws_dto || [];

    if (directors.constructor === Object) {
      directors = [directors];
    }

    let activeDirectors = directors.filter((director) => {
      return director.date_deleted === null;
    });

    let names = activeDirectors.map((director) => {
      return director.first_names + ' ' + director.surname;
    });

    return names.join(', ');
  },

  _previousEnquiries() {
    return this._countDisplayValue(
      'previous_enquiries_collection',
      'previous_enquiry_dto'
    );
  },

  _ppsr_registrations() {
    return this._countDisplayValue(
      'ppsr_finance_statement_summaries',
      'ppsr_finance_statement_summary'
    );
  },

  _defaults() {
    return this._countDisplayValue('defaults_collection', 'defaults_ws_dto');
  },

  _notifications() {
    return this._countDisplayValue(
      'notifications_collection',
      'debtor_notifications_report_ws_dto'
    );
  },

  _judgements() {
    return this._countDisplayValue(
      'judgments_collection',
      'judgment_details_ws_dto'
    );
  },

  _insolvencies() {
    return this._countDisplayValue(
      'insolvencies_collection',
      'company_insolvency_detail'
    );
  },

  render() {
    const { data, consumer } = this.props;

    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      companyDetails = enquiryResult.company_details,
      creditDetails = enquiryResult.credit_details;

    return (
      <ReviewContainer
        subHeading="Executive Summary"
        content_class="content_wide"
        css_class="block_noborder"
        fontSize="medium"
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Company number</th>
                <td>{companyDetails.company.company_number}</td>
                <th>Company name changes</th>
                <td>{this._companyNameChanges()}</td>
              </tr>
              <tr>
                <th>Company status</th>
                <td>{companyDetails.company_status.description}</td>
                <th>Previous Enquiries</th>
                <td>{this._previousEnquiries()}</td>
              </tr>
              <tr>
                <th>NZBN</th>
                <td>{creditDetails.nzbn}</td>
                <th>Number of Suppliers Last EoM</th>
                <td></td>
              </tr>
              <tr>
                <th rowSpan="2">Address</th>
                <td rowSpan="2">{creditDetails.address}</td>
                <th>Total Exposure Last EoM</th>
                <td></td>
              </tr>
              <tr>
                <th>Payment Trend</th>
                <td></td>
              </tr>
              <tr>
                <th>Combined Credit Limit</th>
                <td>
                  {parseFloat(
                    creditDetails.combined_credit_limit
                  ).toLocaleString('en', { maximumFractionDigits: 2 })}
                </td>
                <th>PPSR Registrations</th>
                <td>{this._ppsr_registrations()}</td>
              </tr>
              <tr>
                <th>Directors</th>
                <td>{this._directors()}</td>
                <th>Defaults</th>
                <td>{this._defaults()}</td>
              </tr>
              <tr>
                <th>Time in Business</th>
                <td>{consumer.attributes.age}</td>
                <th>Notifications</th>
                <td>{this._notifications()}</td>
              </tr>
              <tr>
                <th>Generated on</th>
                <td>
                  {moment(creditCheck.sequenceDate).format('DD-MMM-YYYY')}
                </td>
                <th>Judgements</th>
                <td>{this._judgements()}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <th>Three months-DSO average</th>
                <td>{creditDetails.average_dso}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <th>Related Co. Insolvencies</th>
                <td>{this._insolvencies()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },
});

export default ExecutiveSummary;
