import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import get from 'lodash.get';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
import Graph from '../../v2/AgingSummaryGraph/graph';

const AgedBalances = ({ isAllExpanded, data }) => {
  const agedBalances = get(data, 'agedBalances.agedBalances', []);
  const hasSectionData = checkHasSectionData(agedBalances);

  return (
    <ReviewContainer
      subHeading="Aged Balances Trend"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <Graph data={agedBalances} />
      ) : (
        <p>There is no financial data available to display.</p>
      )}
    </ReviewContainer>
  );
};

export default AgedBalances;
