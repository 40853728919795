import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
import get from 'lodash.get';

const JUDGEMENTS_TABLE_CONF = {
  procType: { label: 'Proceeding type' },
  npno: { label: 'Nop Number' },
  npyear: { label: 'Nop Year' },
  court: { label: 'Nop Court' },
  npvariant: { label: 'Nop Variant' },
  civno: { label: 'Civ Number' },
  folioNo: { label: 'Folio Number' },
  folioYear: { label: 'Folio Year' },
  sealedDate: { label: 'Sealed Date', type: 'date' },
  type: { label: 'Type' },
  amount: { label: 'Amount', type: 'money' },
  multipleDefendants: { label: 'Has Multiple Defendants', type: 'yesno' },
};

const JudgementsContent = ({ judgements }) =>
  judgements.map((judgement) => (
    <CreditWorksDataTable
      tableConfig={JUDGEMENTS_TABLE_CONF}
      data={judgement}
    />
  ));

const Judgements = ({ isAllExpanded, data }) => {
  const judgements = get(data, 'executiveSummary.judgmentList', []);
  const hasSectionData = checkHasSectionData(judgements);

  return (
    <ReviewContainer
      subHeading="Judgements"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      <em>Judgments search is limited to the last seven years.</em>
      <br />
      <br />
      {hasSectionData ? (
        <JudgementsContent judgements={judgements} />
      ) : (
        <p>There have been no Judgements filed against this company</p>
      )}
    </ReviewContainer>
  );
};

export default Judgements;
