import React from 'react';
import moment from 'moment';

import styles from '../../../css/CommonReviewBusiness.css';
import historyStyles from '../css/History.css';

var HistoricNames = createClass({
  _names() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      historicNamesCollection =
        enquiryResult.company_details.company_historic_names || {},
      historicNames =
        historicNamesCollection.company_historic_name_ws_dto || [];

    if (historicNames.constructor === Object) {
      historicNames = [historicNames];
    }

    return historicNames.map((name, index) => {
      return (
        <div key={index}>
          <h4>Previous Name</h4>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Name</th>
                <td>{name.previous_name}</td>
              </tr>
              <tr>
                <th>Changed on</th>
                <td>{moment(name.date_changed).format('DD-MMM-YYYY')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  },

  render() {
    return (
      <div className={historyStyles.container}>
        <p>
          <strong>Historic Names</strong>
        </p>
        {this._names()}
      </div>
    );
  },
});

export default HistoricNames;
