import moment from 'moment';

export const getHeaderData = (data: any = {}) => {
  const { scorecard = {}, executiveSummary = {} } = data;
  return {
    ...scorecard,
    companyName: executiveSummary.companyName,
  };
};

export const getCompanySummary = (data: any = {}, sequenceDate) => {
  const { executiveSummary, companyDetails } = data || {};
  return {
    ...executiveSummary,
    companyStatus: companyDetails.status.description,
    sequenceDate,
  };
};

export const getCreditDetails = (data: any = {}) => {
  const { executiveSummary = {} } = data;
  const {
    companyName,
    nzbn,
    address,
    companyNumber,
    directors,
    combinedCreditLimit,
    companyStatus,
    numberOfSuppliersLastEom,
  } = executiveSummary;

  return {
    companyName,
    nzbn,
    address,
    companyNumber,
    directors,
    combinedCreditLimit,
    companyStatus,
    numberOfSuppliersLastEom,
  };
};

export const getCompanyRegistration = (data: any = {}) => {
  const { companyDetails = {}, executiveSummary = {} } = data;
  const { status } = companyDetails;
  const { companyNumber } = executiveSummary;
  return {
    ...companyDetails,
    companyNumber,
    status: status.description,
  };
};

export const getAddresses = (data: any = {}) => {
  const { addresses = {} } = data.companyDetails || {};
  const { physicalAddress = {}, registeredAddress = {} } = addresses;

  return {
    physicalAddress: physicalAddress.address,
    registeredAddress: registeredAddress.address,
  };
};

export const getDirectors = (data: any = {}) => {
  const { directors = [] } = data.companyDetails;
  return directors.filter((director) => !!!director.endDate);
};

export const getPastDirectors = (data: any = {}) => {
  const { directors = [] } = data.companyDetails;
  return directors.filter((director) => !!director.endDate);
};

export const getShareholders = (data: any = {}) => {
  const { shareAllocations = [] } = data.companyDetails;

  return shareAllocations.map((shareAllocation) => {
    const { numberOfShares, shareholders } = shareAllocation;
    const shareholdersName = shareholders.map(
      (shareholder) => shareholder.name
    );
    const shareholdersAddress = shareholders.map(
      (shareholder) => shareholder.address
    );

    return {
      shareholdersName: shareholdersName.join(', '),
      shareholdersAddress: shareholdersAddress.join(', '),
      numberOfShares,
    };
  });
};

export const getStatusHistory = (data: any = {}) => {
  const { statusHistory = [] } = data.companyDetails;
  return statusHistory.map((history) => ({
    statusHistory: `${history.statusDescription} (from ${moment(
      history.startDate
    ).format('DD-MMM-YYYY')} to ${moment(history.endDate).format(
      'DD-MMM-YYYY'
    )})`,
  }));
};

export const getHistoricNames = (data: any = {}) => {
  const { historicNames = [] } = data.companyDetails;
  return historicNames.map((history) => ({
    historicName: `${history.name} (from ${moment(history.startDate).format(
      'DD-MMM-YYYY'
    )} to ${moment(history.endDate).format('DD-MMM-YYYY')})`,
  }));
};

export const getPreviousMonthsData = (data: any = {}) => {
  const { agedBalances = {} } = data;
  return agedBalances.agedBalances
    .map((agedBalance) => ({
      ...agedBalance,
      monthYear: moment(
        `${agedBalance.month}-${agedBalance.year}`,
        'MM-YYYY'
      ).format('MMM-YYYY'),
    }))
    .reverse();
};

export const getCurrentMonthToDate = (data: any = {}) => {
  const { agedBalancesCurrentMonth = {} } = data;
  const { agedBalances = [] } = agedBalancesCurrentMonth || {};
  return agedBalances.map((agedBalance) => ({
    ...agedBalance,
    monthYear: moment(
      `${agedBalance.month}-${agedBalance.year}`,
      'MM-YYYY'
    ).format('MMM-YYYY'),
  }));
};

export const getPpsrFinancingSummary = (data: any = {}) => {
  const { financingStatements = {} } = data;
  const { items = [] } = financingStatements || {};
  return items.map((financingStatementItem) => {
    const {
      financingStatementKey = {},
      securedParties = [],
      collateralTypes = [],
      registrationDate,
    } = financingStatementItem || {};

    return {
      registrationDate,
      registrationNumber: financingStatementKey.registrationNumber,
      securedPartyName: securedParties
        .map((party) => party.securedPartyName)
        .join(', '),
      collateralDescription: collateralTypes
        .map((collateral) => collateral.description)
        .join(', '),
    };
  });
};
