/* eslint-disable max-lines */
import { Radio } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FEATURE_FLAGS } from 'conf';
import get from 'lodash.get';
import NoteModel, { APPLICATION_NOTE_CATEGORIES } from 'models/NoteModel';
import { MobileScreenContext } from 'modules/new-applications/components/Application';
import commonStyles from 'modules/new-applications/css/common.css';
import styles from 'modules/new-applications/css/Notes.css';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import updateAllVersionNotes from 'modules/new-applications/utils/updateAllVersionNotes';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import BorderedTextArea from 'modules/shared/components/inputs/BorderedTextArea';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import FieldWithLabel from 'modules/shared/components/inputs/FieldWithLabel';
import FileWidgetButton from 'modules/shared/components/widgets/interactive/FileWidgetButton';
import useCollapsibleContentState from 'modules/shared/hooks/useCollapsibleContentState';
import React, { Fragment, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import extractAttachmentUrl from 'utils/extractAttachmentUrl';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import * as yup from 'yup';
import { Visibility, VisibilityOff } from '@material-ui/icons/';
import { IconButton, InputAdornment } from 'mui-latest';
import styled from 'styled-components';

const DEFAULT_CATEGORY = 'customer';

const noteFormSchema = yup.object().shape({
  name: yup.string().required('Please enter note title.'),
});

const replyFormSchema = yup.object().shape({
  text: yup.string().required('Please enter note content.'),
});

function useNoteFormState(defaultLabel) {
  const [isNoteFormOpen, setIsNoteFormOpen] = useState(false);

  const onToggleNoteForm = () => setIsNoteFormOpen(!isNoteFormOpen);
  const onCloseNoteForm = () => setIsNoteFormOpen(false);
  const label = isNoteFormOpen ? 'Cancel' : defaultLabel;

  return {
    isNoteFormOpen,
    label,
    onCloseNoteForm,
    onToggleNoteForm,
  };
}

const NoteVersion = ({ version_number }) => {
  return (
    <span
      className={commonStyles.header_version}
    >{` (V${version_number})`}</span>
  );
};

function useAttachmentState() {
  const [attachment, setAttachment] = useState();
  const onSetAttachmentUrl = (data) => setAttachment(data);

  return { attachment, onSetAttachmentUrl };
}

function useDraftNote(defaultCategory = DEFAULT_CATEGORY) {
  const defaultAttributes = { category: defaultCategory, name: '', text: '' };
  const [draftNote, setDraftNote] = useState(defaultAttributes);
  const { attachment, onSetAttachmentUrl } = useAttachmentState();

  const resetDraftNote = () => {
    setDraftNote(defaultAttributes);
    onSetAttachmentUrl(null);
  };

  const onSetDraftNoteAttributes = (attributes) => {
    setDraftNote({ ...draftNote, ...attributes });
  };

  let uploadAttachmentLabel = 'Add attachment';
  if (isPresent(attachment)) {
    uploadAttachmentLabel = 'Edit attachment';
  }

  return {
    attachment,
    draftNote,
    onSetAttachmentUrl,
    onSetDraftNoteAttributes,
    resetDraftNote,
    uploadAttachmentLabel,
  };
}

export function AddNoteButton(props) {
  const applicationId = get(props, 'params.application_id');
  const linkTo = `/dashboard/applications/${applicationId}/notes`;
  const { noteCategory, disabled } = props;

  return (
    <Button
      white
      text="Add note"
      link={{
        pathname: linkTo,
        query: { noteCategory },
      }}
      disabled={disabled}
    />
  );
}

function UploadButton(props) {
  const { disabled, inputId, label, onClick } = props;

  return (
    <FileWidgetButton
      disabled={disabled}
      inputId={inputId}
      isCompact={true}
      handleFileCallback={onClick}
      text={label}
    />
  );
}

function NoteForm(props) {
  const {
    application,
    currentUser,
    errors,
    handleSubmit,
    isTitleEditable,
    note,
    onCloseForm,
    onUpdateApplicationState,
    register,
    submitButtonLabel,
  } = props;

  const { isMobileScreen } = useContext(MobileScreenContext);
  const { attachment, onSetAttachmentUrl } = useAttachmentState();
  const { isLoading, setIsLoading } = useIsLoadingState();

  const onClickUploadAttachment = (data) => onSetAttachmentUrl(data);

  const onSuccessCallback = (newNote) => {
    updateAllVersionNotes(newNote, application);
    setIsLoading(false);
    onUpdateApplicationState();
    onCloseForm();
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    data.file = attachment;

    note.save({
      attributes: data,
      currentUser,
      notable: application,
      onSuccessCallback,
    });
  };

  let uploadButtonLabel = 'Add attachment';
  if (isPresent(note.filename)) {
    uploadButtonLabel = 'Edit attachment';
  }

  let buttonContainerStyle = commonStyles.buttons;
  if (isMobileScreen) {
    buttonContainerStyle = styles.mobile_note_buttons_container;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isTitleEditable && (
        <BorderedTextField
          label="Note title"
          placeholder="Note title"
          defaultValue={note.name}
          inputRef={register}
          name="name"
          error={Boolean(errors.name)}
          helperText={get(errors, 'name.message', ' ')}
        />
      )}
      <BorderedTextArea
        label="Note content"
        placeholder="Note content"
        defaultValue={note.text}
        inputRef={register}
        name="text"
        error={Boolean(errors.text)}
        helperText={get(errors, 'text.message', ' ')}
      />
      <div className={buttonContainerStyle}>
        <Button
          type="submit"
          text={submitButtonLabel}
          loading={isLoading}
          disabled={isLoading}
          disableOnLoading={true}
          style={{ marginRight: 60 }}
        />
        <UploadButton
          key={`note-form-${note.objectId}`}
          disabled={isLoading}
          inputId={`note-form-${note.objectId}`}
          label={uploadButtonLabel}
          onClick={onClickUploadAttachment}
        />
      </div>
    </form>
  );
}

function NoteCategoryRadioComponent(props) {
  const { isMobileScreen } = useContext(MobileScreenContext);
  const { selectedCategory, onChange } = props;

  const radioButtons = APPLICATION_NOTE_CATEGORIES.map((category) => {
    const { label, value } = category;

    return (
      <FormControlLabel
        key={`note-category-radio-${value}`}
        value={value}
        control={<Radio size="small" />}
        label={label}
        classes={{
          label: styles.note_category_radio_label,
        }}
      />
    );
  });

  const content = (
    <RadioGroup
      id="note-category"
      name="gender1"
      value={selectedCategory}
      row={!isMobileScreen}
      onChange={onChange}
    >
      {radioButtons}
    </RadioGroup>
  );

  if (isMobileScreen) {
    return (
      <div className={styles.mobile_category_container}>
        <div>Note category</div>
        {content}
      </div>
    );
  }

  return (
    <FieldWithLabel
      htmlFor="note-category"
      label="Note category"
      withBottomMargin={true}
    >
      {content}
    </FieldWithLabel>
  );
}

function DesktopAddNoteButtons(props) {
  const { isMobileScreen } = useContext(MobileScreenContext);

  if (isMobileScreen) {
    return null;
  }

  const { isLoading, onClear, onClickUploadAttachment, uploadAttachmentLabel } =
    props;

  return (
    <div className={commonStyles.buttons}>
      <Button
        type="submit"
        text="Add"
        loading={isLoading}
        disableOnLoading={true}
        style={{ marginRight: 60 }}
      />
      <Button
        red
        text="Clear"
        type="button"
        disabled={isLoading}
        handleClick={onClear}
      />
      <UploadButton
        key="add-note-uploader"
        disabled={isLoading}
        inputId="add-note-uploader"
        label={uploadAttachmentLabel}
        onClick={onClickUploadAttachment}
      />
    </div>
  );
}

function MobileAddNoteButtons(props) {
  const { isMobileScreen } = useContext(MobileScreenContext);

  if (!isMobileScreen) {
    return null;
  }

  const { isLoading, onClear, onClickUploadAttachment, uploadAttachmentLabel } =
    props;

  return (
    <div>
      <div className={styles.mobile_note_buttons_container}>
        <Button
          red
          text="Clear"
          type="button"
          disabled={isLoading}
          handleClick={onClear}
        />
        <UploadButton
          key="add-note-uploader"
          disabled={isLoading}
          inputId="add-note-uploader"
          label={uploadAttachmentLabel}
          onClick={onClickUploadAttachment}
        />
      </div>
      <div>
        <Button
          type="submit"
          text="Add"
          loading={isLoading}
          disableOnLoading={true}
          style={{ marginRight: 60 }}
        />
      </div>
    </div>
  );
}

function AddNote(props) {
  const { application, currentUser, location, onUpdateApplicationState } =
    props;

  if (application.isDeleted) {
    return null;
  }

  const { isMobileScreen } = useContext(MobileScreenContext);
  const { isLoading, setIsLoading } = useIsLoadingState();
  const { errors, handleSubmit, register, reset } = useForm({
    mode: 'onBlur',
    validationSchema: noteFormSchema,
  });

  const defaultCategory = get(location, 'query.noteCategory', DEFAULT_CATEGORY);

  const {
    attachment,
    draftNote: note,
    onSetAttachmentUrl,
    onSetDraftNoteAttributes,
    resetDraftNote,
    uploadAttachmentLabel,
  } = useDraftNote(defaultCategory);

  const onChangeName = (event) => {
    onSetDraftNoteAttributes({ name: event.target.value });
  };
  const onChangeText = (event) => {
    onSetDraftNoteAttributes({ text: event.target.value });
  };
  const onClickNoteCategory = (event) => {
    onSetDraftNoteAttributes({ category: get(event, 'target.value') });
  };

  const onClickUploadAttachment = (data) => onSetAttachmentUrl(data);

  const onClear = () => {
    setIsLoading(false);
    resetDraftNote();
    onSetAttachmentUrl(null);
    reset();
  };

  const onSuccessCallback = (newNote) => {
    updateAllVersionNotes(newNote, application);
    onUpdateApplicationState();
    onClear();
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const newNote = new NoteModel();

    const attributes = {
      category: note.category,
      file: attachment,
      ...data,
    };

    newNote.save({
      attributes,
      currentUser,
      notable: application,
      onSuccessCallback,
    });
  };

  return (
    <FixedContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <NoteCategoryRadioComponent
          selectedCategory={note.category}
          onChange={onClickNoteCategory}
        />
        <GridContent gridColumnTemplate="two_thirds">
          <div>
            <BorderedTextField
              error={Boolean(errors.name)}
              helperText={get(errors, 'name.message', ' ')}
              inputRef={register}
              label="Note title"
              name="name"
              onChange={onChangeName}
              placeholder="Note title"
              value={note.name}
              customProps={{
                withBottomMargin: !isMobileScreen,
              }}
            />
            <BorderedTextArea
              error={Boolean(errors.text)}
              helperText={get(errors, 'text.message', ' ')}
              inputRef={register}
              label="Note content"
              name="text"
              onChange={onChangeText}
              placeholder="Note content"
              value={note.text}
              customProps={{
                withBottomMargin: !isMobileScreen,
              }}
            />
            <DesktopAddNoteButtons
              isLoading={isLoading}
              onClear={onClear}
              onClickUploadAttachment={onClickUploadAttachment}
              uploadAttachmentLabel={uploadAttachmentLabel}
            />
            <MobileAddNoteButtons
              isLoading={isLoading}
              onClear={onClear}
              onClickUploadAttachment={onClickUploadAttachment}
              uploadAttachmentLabel={uploadAttachmentLabel}
            />
          </div>
        </GridContent>
      </form>
    </FixedContent>
  );
}

function EditNote(props) {
  const { errors, handleSubmit, register } = useForm({
    mode: 'onBlur',
    validationSchema: noteFormSchema,
  });

  return (
    <GridContent gridColumnTemplate="two_thirds">
      <div className={styles.edit_form}>
        <NoteForm
          errors={errors}
          handleSubmit={handleSubmit}
          isTitleEditable={true}
          register={register}
          submitButtonLabel="Update"
          {...props}
        />
      </div>
    </GridContent>
  );
}

function ReplyNote(props) {
  const { parentNote } = props;
  const { errors, handleSubmit, register } = useForm({
    mode: 'onBlur',
    validationSchema: replyFormSchema,
  });

  const note = new NoteModel({
    attributes: {
      name: parentNote.name,
      parent_id: parentNote.id,
    },
  });

  return (
    <GridContent gridColumnTemplate="two_thirds">
      <div className={styles.edit_form}>
        <NoteForm
          errors={errors}
          handleSubmit={handleSubmit}
          isTitleEditable={false}
          note={note}
          register={register}
          submitButtonLabel="Reply"
          {...props}
        />
      </div>
    </GridContent>
  );
}

function NoteAttachment(props) {
  const { note } = props;

  if (isBlank(note.filename)) {
    return null;
  }

  return (
    <a
      href={extractAttachmentUrl(note.file, 'file').url}
      target="_blank"
      rel="noopener noreferrer"
    >
      View attachment
    </a>
  );
}

function NoteDetailsContent(props) {
  const { note } = props;

  return (
    <div className={styles.additional_details}>
      <div className={styles.additional_details_first_column}>{note.text}</div>
      <div>{note.formattedCreatedAt}</div>
      <div></div>
      <div>{note.createdByFullName}</div>
      <div>
        <NoteAttachment note={note} />
      </div>
    </div>
  );
}

function isNoteEditable({ application, currentUser, note }) {
  const noteCreatedAt = new Date(note.createdAt);
  const isOwnerByCurrentUser =
    currentUser.id === note.createdBy || currentUser.id === note.createdById;

  if (application.isArchivedOrDeleted) {
    return false;
  }

  if (application.isAccepted) {
    const approvedAt = new Date(application.approvedAt);

    return isOwnerByCurrentUser && noteCreatedAt >= approvedAt;
  }

  if (application.isDeclined) {
    const finalApprovalModifiedAt = new Date(
      application.finalApprovalModifiedAt
    );

    return isOwnerByCurrentUser && noteCreatedAt >= finalApprovalModifiedAt;
  }

  return isOwnerByCurrentUser;
}

function CollapsibleContent(props) {
  const { children, isVisible } = props;

  const defaultClassNames = [styles.collapsible_container];

  if (isVisible) {
    defaultClassNames.push(styles.collapsible_container_visible);
  }

  return <div className={defaultClassNames}>{children}</div>;
}

function DesktopNotesRow(props) {
  const {
    application,
    childrenNotes,
    currentUser,
    note,
    onUpdateApplicationState,
  } = props;
  const { icon, isOpen, onToggleIsOpen } = useCollapsibleContentState();

  const {
    isNoteFormOpen: isEditNoteFormOpen,
    label: editLabel,
    onCloseNoteForm: onCloseEditNoteForm,
    onToggleNoteForm: onToggleEditNoteForm,
  } = useNoteFormState('Edit');

  const {
    isNoteFormOpen: isReplyNoteFormOpen,
    label: replyLabel,
    onCloseNoteForm: onCloseReplyNoteForm,
    onToggleNoteForm: onToggleReplyNoteForm,
  } = useNoteFormState('Reply');

  const details = childrenNotes.map((childNote, i) => (
    <NoteDetailsContent
      key={`note-details-content-${i + 1}`}
      note={childNote}
      onCloseEditNoteForm={onCloseEditNoteForm}
    />
  ));

  const onToggleEdit = () => {
    onToggleEditNoteForm();
    onCloseReplyNoteForm();
  };

  const onToggleReply = () => {
    onToggleReplyNoteForm();
    onCloseEditNoteForm();
  };

  let editAction = null;
  if (isNoteEditable({ application, currentUser, note })) {
    editAction = (
      <span className={styles.action} onClick={onToggleEdit}>
        {editLabel}
      </span>
    );
  }

  let replyAction = null;
  if (!application.isArchivedOrDeleted) {
    replyAction = (
      <span className={styles.action} onClick={onToggleReply}>
        {replyLabel}
      </span>
    );
  }

  return (
    <div className={styles.grid_row}>
      <div className={styles.grid_table}>
        <div className={styles.first_column} onClick={onToggleIsOpen}>
          {icon}
          <div>
            {note.name}
            <NoteVersion version_number={note.applicationVersionNumber || 1} />
          </div>
        </div>
        <div>{note.formattedCreatedAt}</div>
        <div>{note.formattedUpdatedAt}</div>
        <div>{note.createdByFullName}</div>
        <div>{note.formattedApplicationCategory}</div>
        <div className={styles.actions_container}>
          {editAction}
          {replyAction}
        </div>
      </div>

      <CollapsibleContent isVisible={isOpen}>{details}</CollapsibleContent>

      <CollapsibleContent isVisible={isEditNoteFormOpen}>
        <EditNote
          key={`edit-note-${note.id}`}
          application={application}
          currentUser={currentUser}
          note={note}
          onCloseForm={onCloseEditNoteForm}
          onUpdateApplicationState={onUpdateApplicationState}
        />
      </CollapsibleContent>

      <CollapsibleContent isVisible={isReplyNoteFormOpen}>
        <ReplyNote
          key={`reply-note-${note.id}`}
          application={application}
          currentUser={currentUser}
          parentNote={note}
          onCloseForm={onCloseReplyNoteForm}
          onUpdateApplicationState={onUpdateApplicationState}
        />
      </CollapsibleContent>
    </div>
  );
}

function CategoryFilter(props) {
  const { value, onChange } = props;
  const noteCategories = [...APPLICATION_NOTE_CATEGORIES].sort((a, b) => {
    if (a.label > b.label) {
      return 1;
    }

    if (a.label < b.label) {
      return -1;
    }

    return 0;
  });
  const options = [{ label: 'All', value: 'all' }, ...noteCategories];
  const renderValue = (value) => {
    const optionLabel = (options.find((option) => option.value === value) || {})
      .label;
    return `Category: ${optionLabel}`;
  };

  return (
    <BorderedSelect
      name="Category"
      value={value}
      options={options}
      renderValue={renderValue}
      formControlCustomProps={{
        withBottomMargin: false,
      }}
      selectCustomProps={{
        isCompact: true,
      }}
      onChange={onChange}
    />
  );
}

function filterNotesByCategory({ category, notes }) {
  if (category === 'all') {
    return notes;
  }

  return notes.filter((note) => note.category === category);
}

function DesktopNotesTable(props) {
  const { categoryFilter, onSelectCategory, rows } = props;

  return (
    <FixedContent>
      <div className={`${styles.grid_table} ${styles.grid_header}`}>
        <div className={styles.grid_header_column}>Notes</div>
        <div className={styles.grid_header_column}>Created</div>
        <div className={styles.grid_header_column}>Modified</div>
        <div className={styles.grid_header_column}>Team member</div>
        <div className={styles.grid_header_column}>
          <CategoryFilter value={categoryFilter} onChange={onSelectCategory} />
        </div>
        <div className={styles.grid_header_column}></div>
      </div>
      {rows}
    </FixedContent>
  );
}

function MobileNotes(props) {
  const { rows } = props;

  return <FixedContent header="Notes">{rows}</FixedContent>;
}

function MobileNoteDetails(props) {
  const { note } = props;

  return (
    <div className={styles.mobile_note_details}>
      <div>
        {note.text}
        <span
          className={styles.mobile_note_author}
        >{` - ${note.createdByFullName} (${note.formattedCreatedAt})`}</span>
      </div>
      <div>
        <NoteAttachment note={note} />
      </div>
    </div>
  );
}

function MobileNoteRow(props) {
  const {
    application,
    childrenNotes,
    currentUser,
    note,
    onUpdateApplicationState,
  } = props;
  const { isOpen, onToggleIsOpen } = useCollapsibleContentState();

  const {
    isNoteFormOpen: isEditNoteFormOpen,
    label: editLabel,
    onCloseNoteForm: onCloseEditNoteForm,
    onToggleNoteForm: onToggleEditNoteForm,
  } = useNoteFormState('Edit');

  const {
    isNoteFormOpen: isReplyNoteFormOpen,
    label: replyLabel,
    onCloseNoteForm: onCloseReplyNoteForm,
    onToggleNoteForm: onToggleReplyNoteForm,
  } = useNoteFormState('Reply');

  const onToggleEdit = () => {
    onToggleEditNoteForm();
    onCloseReplyNoteForm();
  };

  const onToggleReply = () => {
    onToggleReplyNoteForm();
    onCloseEditNoteForm();
  };

  const details = childrenNotes.map((childNote, i) => (
    <MobileNoteDetails key={`mobile-note-details-${i + 1}`} note={childNote} />
  ));

  let editButton = null;
  if (isNoteEditable({ application, currentUser, note })) {
    editButton = <Button text={editLabel} handleClick={onToggleEdit} />;
  }

  let replyAction = null;
  if (!application.isArchivedOrDeleted) {
    replyAction = <Button text={replyLabel} handleClick={onToggleReply} />;
  }

  return (
    <FixedContent withBottomSeparator={false}>
      <div className={styles.mobile_note_name} onClick={onToggleIsOpen}>
        {note.name}
        <NoteVersion version_number={note.applicationVersionNumber || 1} />
      </div>
      <CollapsibleContent isVisible={isOpen}>
        {details}
        <div className={styles.mobile_note_buttons_container}>
          {editButton}
          {replyAction}
        </div>
      </CollapsibleContent>
      <CollapsibleContent isVisible={isEditNoteFormOpen}>
        <EditNote
          key={`edit-note-${note.id}`}
          application={application}
          currentUser={currentUser}
          note={note}
          onCloseForm={onCloseEditNoteForm}
          onUpdateApplicationState={onUpdateApplicationState}
        />
      </CollapsibleContent>
      <CollapsibleContent isVisible={isReplyNoteFormOpen}>
        <ReplyNote
          key={`reply-note-${note.id}`}
          application={application}
          currentUser={currentUser}
          parentNote={note}
          onCloseForm={onCloseReplyNoteForm}
          onUpdateApplicationState={onUpdateApplicationState}
        />
      </CollapsibleContent>
    </FixedContent>
  );
}

function NotesContent(props) {
  const { application, currentUser, onUpdateApplicationState } = props;
  const { mainNotes, allNotes: notes } = application;
  const [categoryFilter, setCategoryFilter] = useState('all');
  const { isMobileScreen } = useContext(MobileScreenContext);

  const NoteRowComponent = isMobileScreen ? MobileNoteRow : DesktopNotesRow;

  const rows = filterNotesByCategory({
    category: categoryFilter,
    notes: mainNotes,
  }).map((note) => {
    const { category, text } = note;

    if (FEATURE_FLAGS.FEATURE_FLAG_IMPROVED_NOTES && !category && !text) {
      return null;
    }

    const childrenNotes = notes.filter(
      (childNote) => childNote.id === note.id || childNote.parentId === note.id
    );

    return (
      <NoteRowComponent
        key={`note-${note.id}`}
        application={application}
        currentUser={currentUser}
        childrenNotes={childrenNotes}
        note={note}
        onUpdateApplicationState={onUpdateApplicationState}
      />
    );
  });

  const onSelectCategory = (event) => setCategoryFilter(event.target.value);

  if (isMobileScreen) {
    return (
      <MobileNotes
        categoryFilter={categoryFilter}
        onSelectCategory={onSelectCategory}
        rows={rows}
      />
    );
  }

  return (
    <DesktopNotesTable
      categoryFilter={categoryFilter}
      onSelectCategory={onSelectCategory}
      rows={rows}
    />
  );
}

function InternalAccountNumber(props) {
  const { application, currentUser, onSetAlert } = props;
  const { isMobileScreen } = useContext(MobileScreenContext);
  const { isLoading, setIsLoading } = useIsLoadingState();
  const { formState, handleSubmit, register, reset } = useForm({
    defaultValues: {
      internalAccountNumber: application.internalAccountNumber,
    },
  });
  const { dirty } = formState;

  const onSuccessCallback = (updatedApplication) => {
    const newInternalAccountNumber = updatedApplication.internalAccountNumber;

    reset({ internalAccountNumber: newInternalAccountNumber });
    application.setAttribute('internalAccountNumber', newInternalAccountNumber);

    onSetAlert({ message: 'Internal account number saved.', type: 'success' });
    setIsLoading(false);
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    application.update({
      attributes: {
        internal_account_number: data.internalAccountNumber,
      },
      currentUser,
      onSuccessCallback,
    });
  };

  return FEATURE_FLAGS.FEATURE_FLAG_CUSTOMER_PIN ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="has-text-weight-normal mb-2">Internal account number</div>
      <BorderedTextField
        inputRef={register}
        name="internalAccountNumber"
        customProps={{
          withBottomMargin: !isMobileScreen,
        }}
      />
      <Button
        type="submit"
        text="Update"
        disabled={!dirty}
        loading={isLoading}
        disableOnLoading={true}
        className={styles.mobile_note_account_update_button}
      />
    </form>
  ) : (
    <FixedContent header="Diary notes">
      <GridContent gridColumnTemplate="two_thirds">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="has-text-weight-normal mb-2">
            Internal account number
          </div>
          <BorderedTextField
            inputRef={register}
            name="internalAccountNumber"
            customProps={{
              withBottomMargin: !isMobileScreen,
            }}
          />
          <Button
            type="submit"
            text="Update"
            disabled={!dirty}
            loading={isLoading}
            disableOnLoading={true}
            className={styles.mobile_note_account_update_button}
          />
        </form>
      </GridContent>
    </FixedContent>
  );
}

const IconButtonWrapper = styled(IconButton)`
  margin-right: -14px !important;
  &:hover {
    background-color: transparent !important;
  }
`;

function InternalCustomerPin(props) {
  const { application, currentUser, onSetAlert } = props;
  const { isMobileScreen } = useContext(MobileScreenContext);
  const { isLoading, setIsLoading } = useIsLoadingState();
  const { formState, handleSubmit, register, reset } = useForm({
    defaultValues: {
      internalCustomerPin: application.internalCustomerPin,
    },
  });
  const { dirty } = formState;

  const [showPassword, setShowPassword] = useState(false);

  const onSuccessCallback = (updatedApplication) => {
    const newInternalCustomerPin = updatedApplication.internalCustomerPin;

    reset({ internalCustomerPin: newInternalCustomerPin });
    application.setAttribute('internalCustomerPin', newInternalCustomerPin);

    onSetAlert({ message: 'Internal customer PIN saved.', type: 'success' });
    setIsLoading(false);
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    application.update({
      attributes: {
        internal_customer_pin: data.internalCustomerPin,
      },
      currentUser,
      onSuccessCallback,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={
          'has-text-weight-normal mb-2' + (isMobileScreen ? ' mt-5' : '')
        }
      >
        Customer PIN
      </div>
      <BorderedTextField
        inputRef={register}
        type={showPassword ? 'text' : 'password'}
        name="internalCustomerPin"
        customProps={{
          withBottomMargin: !isMobileScreen,
          endAdornment: (
            <InputAdornment position="end">
              <IconButtonWrapper
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((prevState) => !prevState)}
                disableFocusRipple
                disableRipple
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButtonWrapper>
            </InputAdornment>
          ),
        }}
      />
      <Button
        type="submit"
        text="Update"
        disabled={!dirty}
        loading={isLoading}
        disableOnLoading={true}
        className={styles.mobile_note_account_update_button}
      />
    </form>
  );
}

const DiaryNotesForm = ({ children }) => {
  return (
    <FixedContent header="Diary notes">
      <GridContent>{children}</GridContent>
    </FixedContent>
  );
};

export default function Notes(props) {
  const { isMobileScreen } = useContext(MobileScreenContext);

  const content = (
    <Fragment>
      {FEATURE_FLAGS.FEATURE_FLAG_CUSTOMER_PIN ? (
        <DiaryNotesForm>
          <InternalAccountNumber {...props} />
          <InternalCustomerPin {...props} />
        </DiaryNotesForm>
      ) : (
        <InternalAccountNumber {...props} />
      )}
      <AddNote {...props} />
      <NotesContent {...props} />
    </Fragment>
  );

  if (isMobileScreen) {
    return content;
  }

  return <ScrollableContent>{content}</ScrollableContent>;
}
