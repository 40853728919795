import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import { getHistoricNames } from '../shared/utils';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
const HISTORIC_NAMES_TABLE_CONF = {
  value: { keyName: 'historicName' },
};
const HistoricNamesContent = ({ historicNames }) => (
  <CreditWorksDataTable
    tableConfig={HISTORIC_NAMES_TABLE_CONF}
    data={historicNames}
    iterateData
    cols={1}
  />
);
const HistoricNames = ({ isAllExpanded, data }) => {
  const historicNames = getHistoricNames(data);
  const hasSectionData = checkHasSectionData(historicNames);

  return (
    <ReviewContainer
      subHeading="Historic Names"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <HistoricNamesContent historicNames={historicNames} />
      ) : (
        <p>There are no Historic Names on file for this company.</p>
      )}
    </ReviewContainer>
  );
};

export default HistoricNames;
