import React from 'react';

import { H4Wrapped, SummaryWrapper, TwoColumnWrapper } from '../shared/styles';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
import get from 'lodash.get';
import { getCompanySummary } from '../shared/utils';

const COMPANY_SUMMARY_LEFT_CONF = {
  companyNumber: { label: 'Company Number' },
  companyStatus: { label: 'Company Status' },
  nzbn: { label: 'NZBN' },
  companyNameChanges: { label: 'Company Name Changes' },
  combinedCreditLimit: {
    label: 'Total Credit available from Data suppliers',
    type: 'money',
  },
  directors: { label: 'Directors' },
  timeInBusiness: { label: 'Time in Business' },
  industry: { label: 'Industry' },
  address: { label: 'Address' },
};

const COMPANY_SUMMARY_RIGHT_CONF = {
  previousEnquiries: { label: 'Previous Enquiries' },
  numberOfSuppliersLastEom: {
    label: 'Number of Data Suppliers Last EoM',
  },
  totalExposureEom: { label: 'Total Exposure Last EoM', type: 'money' },
  dsoTrend: {
    label: 'Days Sales Outstanding (DSO) trend',
    type: 'trend_status',
  },
  ppsrRegistrations: { label: 'PPSR Registrations' },
  defaults: { label: 'Defaults' },
  notifications: { label: 'Notifications' },
  judgments: { label: 'Judgments' },
  threeMonthDsoAverage: { label: 'Three-month DSO Average' },
  relatedCompanyInsolvencies: { label: 'Related Co. Insolvencies' },
  buildingConsentsCount: { label: 'Building Consents issued' },
  sequenceDate: { label: 'Report generated', type: 'date' },
};

const CompanySummary = ({ data, sequenceDate }) => {
  const companySummary = getCompanySummary(data, sequenceDate);

  const hasSectionData = checkHasSectionData(companySummary);

  return (
    <SummaryWrapper>
      <H4Wrapped>Company Summary</H4Wrapped>
      {hasSectionData ? (
        <TwoColumnWrapper>
          <CreditWorksDataTable
            tableConfig={COMPANY_SUMMARY_LEFT_CONF}
            data={companySummary}
          />
          <CreditWorksDataTable
            tableConfig={COMPANY_SUMMARY_RIGHT_CONF}
            data={companySummary}
          />
        </TwoColumnWrapper>
      ) : (
        'No data in file'
      )}
    </SummaryWrapper>
  );
};

export default CompanySummary;
