import Addresses from './Addresses';
import CompanyRegistration from './CompanyRegistration';
import Defaults from './Defaults';
import Directors from './Directors';
import Documents from './Documents';
import HistoricNames from './HistoricNames';
import Insolvencies from './Insolvencies';
import Judgments from './Judgments';
import PreviousEnquiries from './PreviousEnquiries';
import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import Shareholders from './Shareholders';
import StatusHistory from './StatusHistory';

var History = createClass({
  render() {
    const { data, isAllExpanded } = this.props;

    return (
      <ReviewContainer
        subHeading="History"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <Defaults data={data} />
        <Judgments data={data} />
        <Insolvencies data={data} />
        <PreviousEnquiries data={data} />
        <CompanyRegistration data={data} />
        <Addresses data={data} />
        <HistoricNames data={data} />
        <StatusHistory data={data} />
        <Directors data={data} />
        <Shareholders data={data} />
        <Documents data={data} />
      </ReviewContainer>
    );
  },
});

export default History;
