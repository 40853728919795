import React from 'react';

import styles from '../../../css/CommonReviewBusiness.css';
import historyStyles from '../css/History.css';

var Insolvencies = createClass({
  _insolvencies() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      insolvenciesCollection = enquiryResult.insolvencies_collection || {},
      insolvencyDetails =
        insolvenciesCollection.company_insolvency_detail || [];

    if (insolvencyDetails.constructor === Object)
      insolvencyDetails = [insolvencyDetails];

    if (insolvencyDetails.length === 0) {
      return (
        <div>
          <em>
            This information is retrieved from the New Zealand Government's
            Ministry of Economic Development, Insolvency and Trustee Service and
            includes all insolvencies administered by the Official Assignee.
            This excludes information on liquidations where the Official
            Assignee is not the liquidator.
          </em>
          <h4>
            There are no insolvency records filed with the Official Assignee for
            this company.
          </h4>
        </div>
      );
    }

    return insolvencyDetails.map((insolvency, index) => {
      return (
        <div key={index}>
          <h4>Insolvency</h4>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Company Number</th>
                <td>{insolvency.company_number}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  },

  render() {
    return (
      <div className={historyStyles.container}>
        <p>
          <strong>Insolvencies</strong>
        </p>
        {this._insolvencies()}
      </div>
    );
  },
});

export default Insolvencies;
