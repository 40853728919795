import logo from 'images/centrix-logo-v2.png';
import ErrorBoundary from 'modules/shared/components/containers/ErrorBoundary';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import Button from 'modules/shared/components/inputs/Button';
import React from 'react';

import styles from './centrix.css';
import EnquiryDetails from './EnquiryDetails';
import sections from './sections';
import ReportPdf from '../../australia/equifax/components/shared/ReportPdf';
import ReportSection from '../../australia/equifax/components/shared/ReportSection';

class CentrixComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      isAllExpanded: false,
    };
  }

  _expandAll() {
    this.setState({ isAllExpanded: !this.state.isAllExpanded });
  }

  _expandAllButton() {
    let message = '+ expand all';
    if (this.state.isAllExpanded) message = '- collapse all';

    return (
      <Button
        small
        text={message}
        style={{ fontSize: '10px', margin: '1em 0 1em 66px' }}
        handleClick={this._expandAll.bind(this)}
      />
    );
  }

  _isError(statusMessage) {
    if (Array.isArray(statusMessage)) {
      return !!statusMessage.find(
        (m) => m.message_text && m.message_type === 'Error'
      );
    }
    return statusMessage.message_type === 'Error';
  }

  render() {
    const { data = {}, pdf, signed_pdf } = this.props;
    const { isAllExpanded } = this.state;

    const statusMessages = data.status_messages || {};
    const statusMessage = statusMessages.status_message || [];
    const isError = this._isError(statusMessage);

    return (
      <div>
        {isError && (
          <div>
            <div className={styles.header_container}>
              <img src={logo} alt="Centrix" className={styles.logo} />
            </div>
            <div>
              <ReviewContainer
                content_class="content_wide"
                css_class="block_noborder"
              >
                <div>There is an issue with the credit check.</div>
                <p>
                  Contact{' '}
                  <a href="mailto:support@1centre.com">support@1centre.com</a>{' '}
                  or live chat with us.
                </p>
              </ReviewContainer>
            </div>
          </div>
        )}
        {!isError && (
          <div>
            <div className={styles.header_container}>
              <ErrorBoundary>
                <EnquiryDetails data={data} />
              </ErrorBoundary>
              <img src={logo} alt="Centrix" className={styles.logo} />
            </div>
            {this._expandAllButton()}
            <div>
              {sections.map((section, i) => (
                <ErrorBoundary key={i}>
                  <ReviewContainer
                    subHeading={section.title}
                    content_class="content_wide"
                    css_class="block_noborder"
                    collapsible={true}
                    isAllExpanded={isAllExpanded}
                    fontSize="medium"
                    fontColour={true}
                  >
                    {React.createElement(section.component, { data })}
                  </ReviewContainer>
                </ErrorBoundary>
              ))}
            </div>
            {this._expandAllButton()}
            <ReportSection>
              <ReportPdf pdf={pdf} signed_pdf={signed_pdf} />
            </ReportSection>
          </div>
        )}
      </div>
    );
  }
}

export default CentrixComponent;
