import React, { useState } from 'react';
import ErrorPage from './sections/ErrorPage';
import Header from './sections/Header';
import { ReportContainer, ReportBody } from './shared/styles';
import Button from 'modules/shared/components/inputs/Button';
import CompanySummary from './sections/CompanySummary';
import Addresses from './sections/Addresses';
import CreditDetails from './sections/CreditDetails';
import Notifications from './sections/Notifications';
import Defaults from './sections/Defaults';
import Judgements from './sections/Judgements';
import Insolvencies from './sections/Insolvencies';
import CreditActivity from './sections/CreditActivities';
import CompanyRegistration from './sections/CompanyRegistration';
import Filings from './sections/Filings';
import AgedBalances from './sections/AgedBalancesTrend';
import DSOTrend from './sections/DSOTrend';
import CurrentMonthToDate from './sections/CurrentMonthToDate';
import PreviousMonths from './sections/PreviousMonths';
import ReportSection from '../../australia/equifax/components/shared/ReportSection';
import ReportPdf from '../../australia/equifax/components/shared/ReportPdf';
import StatusHistory from './sections/StatusHistory';
import PastDirectors from './sections/PastDirectors';
import Directors from './sections/Directors';
import Shareholders from './sections/Shareholders';
import PPSRFinancingSummary from './sections/PPSRSummary';
import PPSRFinancingDetails from './sections/PPSRDetail';
import IndustrySummary from './sections/IndustrySummary';
import DirectorAffiliation from './sections/DirectorAffiliations';
import ParentCompanies from './sections/ParentCompanies';
import ChildCompanies from './sections/ChildCompanies';
import HistoricNames from './sections/HistoricNames';

const CreditWorksReport = ({
  data = {},
  pdf,
  signed_pdf,
  status,
  sequence_date,
}) => {
  const [isAllExpanded, setIsAllExpanded] = useState(true);

  const ExpandAllButton = () => {
    const buttonLabel = isAllExpanded ? '- collapse all' : '+ expand all';

    return (
      <Button
        small
        text={buttonLabel}
        style={{
          fontSize: '10px',
          margin: '1em 0 1em 66px',
          maxWidth: '100px',
        }}
        handleClick={() => setIsAllExpanded(!isAllExpanded)}
      />
    );
  };

  if (status === 'errored') {
    return <ErrorPage />;
  }

  return (
    <ReportContainer>
      <Header data={data} />
      <CompanySummary data={data} sequenceDate={sequence_date} />
      <ReportBody>
        <ExpandAllButton />
        <AgedBalances isAllExpanded={isAllExpanded} data={data} />
        <DSOTrend isAllExpanded={isAllExpanded} data={data} />
        <IndustrySummary isAllExpanded={isAllExpanded} data={data} />
        <CurrentMonthToDate isAllExpanded={isAllExpanded} data={data} />
        <PreviousMonths isAllExpanded={isAllExpanded} data={data} />
        <CreditDetails isAllExpanded={isAllExpanded} data={data} />
        <Notifications isAllExpanded={isAllExpanded} data={data} />
        <Defaults isAllExpanded={isAllExpanded} data={data} />
        <Judgements isAllExpanded={isAllExpanded} data={data} />
        <Insolvencies isAllExpanded={isAllExpanded} data={data} />
        <CreditActivity isAllExpanded={isAllExpanded} data={data} />
        <CompanyRegistration isAllExpanded={isAllExpanded} data={data} />
        <Addresses isAllExpanded={isAllExpanded} data={data} />
        <HistoricNames isAllExpanded={isAllExpanded} data={data} />
        <Filings isAllExpanded={isAllExpanded} data={data} />
        <StatusHistory isAllExpanded={isAllExpanded} data={data} />
        <Directors isAllExpanded={isAllExpanded} data={data} />
        <PastDirectors isAllExpanded={isAllExpanded} data={data} />
        <Shareholders isAllExpanded={isAllExpanded} data={data} />
        <DirectorAffiliation isAllExpanded={isAllExpanded} data={data} />
        <ParentCompanies isAllExpanded={isAllExpanded} data={data} />
        <ChildCompanies isAllExpanded={isAllExpanded} data={data} />
        <PPSRFinancingSummary isAllExpanded={isAllExpanded} data={data} />
        <PPSRFinancingDetails isAllExpanded={isAllExpanded} data={data} />
        <ExpandAllButton />
        <ReportSection>
          <ReportPdf pdf={pdf} signed_pdf={signed_pdf} />
        </ReportSection>
      </ReportBody>
    </ReportContainer>
  );
};

export default CreditWorksReport;
