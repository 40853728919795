import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { getCurrentMonthToDate } from '../shared/utils';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
import CreditWorksDataTable from '../shared/CreditworksDataTable';

const CURRENT_MONTH_TO_DATE_CONF = {
  monthYear: { label: 'Month' },
  currentPurchases: { label: 'Current' },
  dueThisMonth: { label: 'DueNow' },
  overdue1Month: { label: '+30' },
  overdue2Months: { label: '+60' },
  overdue3Months: { label: '+90' },
  unallocated: { label: 'Unalloc' },
  totalDebt: { label: 'Total' },
  retentions: { label: 'Ret' },
  dso: { label: 'DSO' },
  vendorCount: { label: 'Suppliers' },
};

const CurrentMonthToDateContent = ({ currentMonthToDate }) => (
  <CreditWorksDataTable
    layout="horizontal"
    tableConfig={CURRENT_MONTH_TO_DATE_CONF}
    data={currentMonthToDate}
    cols={11}
    fullWidth
  />
);

const CurrentMonthToDate = ({ isAllExpanded, data }) => {
  const currentMonthToDate = getCurrentMonthToDate(data);
  const hasSectionData = checkHasSectionData(currentMonthToDate);

  return (
    <ReviewContainer
      subHeading="Current Month to Date"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <CurrentMonthToDateContent currentMonthToDate={currentMonthToDate} />
      ) : (
        <p>Sorry, this information is currently unavailable.</p>
      )}
    </ReviewContainer>
  );
};

export default CurrentMonthToDate;
