import React, { Fragment } from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import get from 'lodash.get';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';

const FILING_TABLE_CONF = {
  key: { keyName: 'filingDate', type: 'date' },
  value: { keyName: 'filingLinkedDescription' },
};

const FilingsContent = ({ filingsTableData }) => (
  <Fragment>
    <em>Filings search is limited to the last two years.</em>
    <CreditWorksDataTable
      tableConfig={FILING_TABLE_CONF}
      data={filingsTableData}
      iterateData
    />
  </Fragment>
);

const Filings = ({ isAllExpanded, data }) => {
  const filingsData = get(data, 'companyDetails.filings', []);
  const filingsTableData = filingsData.map((filing) => ({
    filingDate: filing.registrationDate,
    filingLinkedDescription: (
      <a href={filing.url.href} target="_blank">
        {filing.filingCodeDescription}
      </a>
    ),
  }));
  const hasSectionData = checkHasSectionData(filingsTableData);

  return (
    <ReviewContainer
      subHeading="Filings"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <FilingsContent filingsTableData={filingsTableData} />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default Filings;
