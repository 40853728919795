import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import get from 'lodash.get';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';
import SupplierByIndustryPieChart from '../../v2/SuppliersByIndustryPieChart';

const IndustrySummary = ({ isAllExpanded, data }) => {
  const industrySummary = get(
    data,
    'industrySummary.agedBalanceGroupIndustrySummaries',
    []
  );
  const hasSectionData = checkHasSectionData(industrySummary);

  return (
    <ReviewContainer
      subHeading="Industry Summary"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <SupplierByIndustryPieChart data={industrySummary} />
      ) : (
        <p>There is no financial data available to display.</p>
      )}
    </ReviewContainer>
  );
};

export default IndustrySummary;
