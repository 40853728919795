import styles from 'modules/new-applications/css/IdentificationChecks.css';
import React, { Fragment, ReactElement } from 'react';
import {
  AUTHENTICITY_SCORE_MAP,
  getAuthenticityOutcome,
  getAuthenticityScoreDetails,
  getAuthenticityScoreDetails_deprecated,
  getAuthenticityOutcome_deprecated,
} from '../utils/getAuthenticityOutcome';
import SignatureDetails from '../SignatureDetails';
import { getOtherTypeTooltipLabel } from '../utils/utils';
import SignatureHelpTooltip from '../SignatureTooltip';
import { FEATURE_FLAGS } from 'conf';

const AuthenticityCheckDeprecated = ({
  authenticityScore,
  followNewRule,
  isOtherIdentificationType,
}) => {
  const currAuthScore = authenticityScore ? parseFloat(authenticityScore) : 0;
  const authenticityScoreDisplay = Math.trunc(currAuthScore * 100) / 100;

  const minPassScore = followNewRule ? 0.7 : 0.75;
  const currentAuthScoreLabel = isOtherIdentificationType
    ? 'N/A'
    : getAuthenticityOutcome_deprecated(currAuthScore, minPassScore);

  const authentictyScoreColor = currAuthScore >= minPassScore ? 'green' : 'red';
  const outcomeColor = {
    color: isOtherIdentificationType ? 'black' : authentictyScoreColor,
  };
  return (
    <Fragment>
      <div className={styles.e_signature_header}>
        <span className="underlined">{'Authenticity check:'}</span>
        <span style={outcomeColor}>&nbsp;{currentAuthScoreLabel}</span>
        <SignatureHelpTooltip noMaxWidth={!isOtherIdentificationType}>
          {isOtherIdentificationType ? (
            <span>{getOtherTypeTooltipLabel('authenticity')}</span>
          ) : (
            <Fragment>
              <span className={styles.nowrap}>
                {getAuthenticityScoreDetails_deprecated(
                  currAuthScore,
                  minPassScore
                )}
              </span>
              <br />
              {followNewRule ? (
                <span className={styles.subtext}>
                  (0.7-1 Pass / 0.5-0.69 Warning / 0-0.49 Fail)
                </span>
              ) : (
                <span className={styles.subtext}>
                  (0.75-1 Pass/ 0.5-0.75 Warning / 0-0.5 Fail)
                </span>
              )}
            </Fragment>
          )}
        </SignatureHelpTooltip>
        {!isOtherIdentificationType && (
          <SignatureDetails
            label="Authenticity score"
            content={
              <span>{`${authenticityScoreDisplay}/1 (${currentAuthScoreLabel})`}</span>
            }
          />
        )}
      </div>
    </Fragment>
  );
};

const AuthenticityCheck = (props): ReactElement | null => {
  const {
    authenticityScore,
    authenticityScoreVersion,
    isOtherIdentificationType,
  } = props;

  if (
    [undefined, null].includes(authenticityScoreVersion) ||
    !FEATURE_FLAGS.FEATURE_FLAG_AUTHENTICITY_SCORE_VERSIONING
  ) {
    return <AuthenticityCheckDeprecated {...props} />;
  }

  const currAuthScore = authenticityScore ? parseFloat(authenticityScore) : 0;
  const authenticityScoreDisplay = Math.trunc(currAuthScore * 100) / 100;
  const { min_passing, min_accepted, fail } =
    AUTHENTICITY_SCORE_MAP[authenticityScoreVersion];
  const currentAuthScoreLabel = isOtherIdentificationType
    ? 'N/A'
    : getAuthenticityOutcome(currAuthScore, authenticityScoreVersion);
  const authenticityScoreColor = currAuthScore >= min_passing ? 'green' : 'red';
  const outcomeColor = {
    color: isOtherIdentificationType ? 'black' : authenticityScoreColor,
  };

  return (
    <Fragment>
      <div className={styles.e_signature_header}>
        <span className="underlined">{'Authenticity check:'}</span>
        <span style={outcomeColor}>&nbsp;{currentAuthScoreLabel}</span>
        <SignatureHelpTooltip noMaxWidth={!isOtherIdentificationType}>
          {isOtherIdentificationType ? (
            <span>{getOtherTypeTooltipLabel('authenticity')}</span>
          ) : (
            <Fragment>
              <span className={styles.nowrap}>
                {getAuthenticityScoreDetails(
                  currAuthScore,
                  authenticityScoreVersion
                )}
              </span>
              <br />
              <span className={styles.subtext}>
                {`(${min_passing}-1 Pass / ${min_accepted}-${
                  min_passing - 0.01
                } Warning / 0-${fail} Fail)`}
              </span>
            </Fragment>
          )}
        </SignatureHelpTooltip>
        {!isOtherIdentificationType && (
          <SignatureDetails
            label="Authenticity score"
            content={
              <span>{`${authenticityScoreDisplay}/1 (${currentAuthScoreLabel})`}</span>
            }
          />
        )}
      </div>
    </Fragment>
  );
};

export default AuthenticityCheck;
