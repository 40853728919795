import React from 'react';
import moment from 'moment';

import historyStyles from '../css/History.css';

var Documents = createClass({
  _documents() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      companyDocuments = enquiryResult.company_documents || {},
      documents = companyDocuments.company_document_ws_dto || [];

    if (documents.constructor === Object) documents = [documents];

    return documents.map((document, index) => {
      return (
        <tr key={index}>
          <td>{moment(document.date_registered).format('DD-MMM-YYYY')}</td>
          <td>{document.description}</td>
        </tr>
      );
    });
  },

  render() {
    return (
      <div className={historyStyles.container}>
        <p>
          <strong>Documents</strong>
        </p>
        {this._documents()}
      </div>
    );
  },
});

export default Documents;
