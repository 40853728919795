import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import { getCreditDetails } from '../shared/utils';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';

const CREDIT_DETAILS_TABLE_CONF = {
  companyName: { label: 'Legal Name' },
  nzbn: { label: 'NZBN' },
  address: { label: 'Address' },
  companyNumber: { label: 'Company Number' },
  companyStatus: { label: 'Company Status' },
  directors: { label: 'Directors' },
  combinedCreditLimit: {
    label: 'Total Credit available from Data Suppliers',
    type: 'money',
  },
  numberOfSuppliersLastEom: {
    label: 'Current number of suppliers',
  },
};

const CreditDetailsContent = ({ creditDetails }) => (
  <CreditWorksDataTable
    tableConfig={CREDIT_DETAILS_TABLE_CONF}
    data={creditDetails}
  />
);
const CreditDetails = ({ isAllExpanded, data }) => {
  const creditDetails = getCreditDetails(data);
  const hasSectionData = checkHasSectionData(creditDetails);

  return (
    <ReviewContainer
      subHeading="Credit Details"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <CreditDetailsContent creditDetails={creditDetails} />
      ) : (
        <p>There have been no Credit Details filed against this company</p>
      )}
    </ReviewContainer>
  );
};

export default CreditDetails;
