import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CreditWorksDataTable from '../shared/CreditworksDataTable';
import get from 'lodash.get';
import { checkHasSectionData } from '../../../centrix/commercial/sections/utils';

const NOTIFICATIONS_TABLE_CONF = {
  dateSent: { label: 'Date/time', type: 'datetime' },
  notificationTypeDescription: { label: 'Description' },
  vendorName: { label: 'Notified by' },
};

const NotificationsContent = ({ notifications }) =>
  notifications.map((notification) => (
    <CreditWorksDataTable
      tableConfig={NOTIFICATIONS_TABLE_CONF}
      data={notification}
    />
  ));

const Notifications = ({ isAllExpanded, data }) => {
  const notifications = get(data, 'executiveSummary.debtorNotifications', []);
  const hasSectionData = checkHasSectionData(notifications);

  return (
    <ReviewContainer
      subHeading="Notifications"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <NotificationsContent notifications={notifications} />
      ) : (
        <p>There have been no Notifications filed against this company</p>
      )}
    </ReviewContainer>
  );
};

export default Notifications;
