import React from 'react';
import { CWTable } from './styles';
import { ICreditWorksTable } from './types';
import moment from 'moment';
import formatMoney from 'utils/formatMoney';
import { toBoolean } from 'utils';
import { CREDITWORKS_COLORS } from 'variables/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const trendStatus = {
  Improving: {
    color: CREDITWORKS_COLORS.fernGreen,
    icon: 'caret-down',
    size: '2x',
  },
  'No Change': {
    color: CREDITWORKS_COLORS.olivine,
    icon: 'circle',
  },
  Worsening: {
    color: CREDITWORKS_COLORS.cinnabar,
    icon: 'caret-up',
    size: '2x',
  },
};

const formatText = (value, type) => {
  switch (type) {
    case 'date':
      return moment(value).format('DD-MMM-YYYY');
    case 'money':
      return `$${formatMoney(value)}`;
    case 'yesno':
      return toBoolean(value) ? 'Yes' : 'No';
    case 'trend_status': {
      const currentTrend = trendStatus[value];
      const { color, icon, size } = currentTrend || {};
      {
        !currentTrend ? (
          value
        ) : (
          <FontAwesomeIcon
            icon={['fas', icon]}
            color={color}
            size={size ? size : ''}
          />
        );
      }
    }
    default:
      return value;
  }
};

const CreditWorksDataTable = ({
  layout = 'vertical',
  tableConfig,
  data,
  iterateData = false,
  cols,
  fullWidth,
  colsWidth,
}: ICreditWorksTable) => {
  if (!!!tableConfig || !!!data) return null;

  if (layout === 'vertical') {
    return (
      <CWTable cols={cols} fullWidth={fullWidth} colsWidth={colsWidth}>
        <tbody>
          {iterateData
            ? data.map((item, index) => (
                <tr
                  key={
                    item[(tableConfig.key || tableConfig.value).keyName] + index
                  }
                >
                  {tableConfig.key && (
                    <th>
                      {tableConfig.key.type
                        ? formatText(
                            item[tableConfig.key.keyName],
                            tableConfig.key.type
                          )
                        : item[tableConfig.key.keyName]}
                    </th>
                  )}
                  <td>
                    {![undefined, null].includes(
                      item[tableConfig.value.keyName]
                    )
                      ? tableConfig.value.type
                        ? formatText(
                            item[tableConfig.value.keyName],
                            tableConfig.value.type
                          )
                        : item[tableConfig.value.keyName]
                      : '-'}
                  </td>
                </tr>
              ))
            : Object.entries(tableConfig).map(
                ([key, value]: [key: any, value: any], index) => (
                  <tr key={key + index}>
                    <th>{value.label}</th>
                    <td>
                      {![undefined, null].includes(data[key])
                        ? value.type
                          ? formatText(data[key], value.type)
                          : data[key]
                        : '-'}
                    </td>
                  </tr>
                )
              )}
        </tbody>
      </CWTable>
    );
  }

  return (
    <CWTable cols={cols} fullWidth={fullWidth} colsWidth={colsWidth}>
      <thead>
        <tr>
          {Object.entries(tableConfig).map(
            ([key, value]: [key: any, value: any], index) => (
              <th>{value.label}</th>
            )
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((data, index) => (
          <tr key={index}>
            {Object.entries(tableConfig).map(
              ([key, value]: [key: any, value: any], index) => (
                <td key={key + index}>
                  {![undefined, null].includes(data[key])
                    ? value.type
                      ? formatText(data[key], value.type)
                      : data[key]
                    : '-'}
                </td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </CWTable>
  );
};

export default CreditWorksDataTable;
